import { Drawer, IconButton, ListItem, SwipeableDrawer } from '@material-ui/core';
import { Close }                                         from '@material-ui/icons';
import React, { FC, useCallback, useContext, useState }  from 'react';
import { useHistory }                                    from 'react-router';
import { NavLink, Link }                                 from 'react-router-dom';
import styled, { css }                                   from 'styled-components';
import { ReactComponent as IconActivity }                from '../../images/icons/activity-icon.svg';
import { ReactComponent as IconCalendar }                from '../../images/icons/calendar-icon.svg';
import { ReactComponent as IconDashboard }               from '../../images/icons/dashboard-icon.svg';
import { ReactComponent as IconLocate }                  from '../../images/icons/locate-icon.svg';
import { ReactComponent as IconFamily }                  from '../../images/icons/user-friends.svg';
import { ReactComponent as Logo }                        from '../../images/logo/sidebar-logo.svg';
import { TermsModal }                                    from '../shared/components/modals/Terms/TermsModal';
import { TermsServiceModal }                             from '../shared/components/modals/Terms/TermsServiceModal';
import { EAppRoutes, EAuthRoutes }                       from '../shared/constants/constants';
import { withQueryParams }                               from '../shared/functions';
import AuthorizationContext                              from '../shared/utils/withAuthorization/authorizationContext';
import {
  Authorized,
  EFamilyAccountType,
  TFamilyAccountType,
  adminRoles,
  allRoles,
}                                                        from '../shared/utils/withAuthorization/withAuthorization';

export const DRAWER_WIDTH = 270;

const SidebarLogo = styled(Logo)`
  margin: 24px 25px 10px;
  height: 51px;
  width: 118px;
  display: none;
  cursor: pointer;
`;

const IconClose = styled(Close)`&& {
  color: #fac05e;
  font-size: 32px;
}`;

const ChangeSubscriptionWrapper = styled(Link)`&& {
  display: flex;
  width: 100%;
  padding: 9px 33px;
  color: ${ ({ color }) => color || '#fff' };
  align-items: center;
  line-height: 21px;
  font-weight: normal;
  font-family: 'Jost';
}`;

const SidebarHeader = styled.div`
  margin: 0 5px 7px;
`;

const Wrapper = styled.div`${ ({ theme: { palette, breakpoints } }) => css`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  font-size: 18px;
  font-weight: 500;
  padding: 9px 0 18px;
  color: ${ palette.common.white };
  padding-top: env(safe-area-inset-top);

  ${ breakpoints.up('lg') } {
    .MuiButtonBase-root {
      display: none;
    }

    ${ SidebarLogo } {
      display: block;
    }
  }
` }`;

const Separator = styled.div`
  height: 0;
  width: calc(100% - 30px);
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  margin: 25px auto 23px;
`;

const DrawerStyles = css`
  .MuiDrawer-paper {
    position: relative;
    width: ${ DRAWER_WIDTH }px;
    background-color: #5b7bc5;
  }

  .MuiDrawer-paperAnchorDockedLeft {
    border: none;
  }
`;

const StyledDrawer = styled(Drawer)`
  ${ DrawerStyles }
`;

const StyledSwipeableDrawer = styled(SwipeableDrawer)`
  ${ DrawerStyles }
`;

const MenuItem = styled(ListItem)`&& {
  display: flex;
  width: 100%;
  padding: 9px 33px;
  color: ${ ({ color }) => color || '#fff' };
  align-items: center;
  line-height: 21px;
  font-weight: normal;
  font-family: 'Jost';

  svg {
    margin-right: 12px;
    width: 24px;
  }
}`;

const Section = styled.div`
  padding: 0;

  .selected {
    ${ MenuItem } {
      font-weight: 600;
    }
  }
`;

const BottomMenuSection = styled(Section)`&& {
  height: 100%;
  align-content: end;

  .MuiButtonBase-root {
    font-size: 16px;
    font-weight: 600;
  }
}`;

interface ISidebarProps {
  sidebarOpen: boolean;
  isMobile: boolean;
  isAllowedForStatistics?: boolean;
  toggleDrawer: () => void;
}

interface IMenuItem {
  icon?: JSX.Element;
  title: string;
  to: string;
  roles?: TFamilyAccountType[];
}

export const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

export const Sidebar: FC<ISidebarProps> = ({ sidebarOpen, isAllowedForStatistics, isMobile, toggleDrawer }) => {
  const currentUser = useContext(AuthorizationContext);
  const [ termsModalOpened, setTermsModalOpened ] = useState(false);
  const [ termsServiceModalOpened, setTermsServiceModalOpened ] = useState(false);
  const history = useHistory();

  const withMemberId = (route: string) => withQueryParams(route, {
    id: currentUser?.data?.id,
  });

  const primaryMenu: IMenuItem[] = [ {
    icon  : <IconDashboard />,
    title : 'Dashboard',
    to    : withMemberId(EAppRoutes.Dashboard),
  }, {
    icon  : <IconActivity />,
    title : 'Activity',
    to    : withMemberId(EAppRoutes.Activity),
  }, {
    icon  : <IconFamily />,
    title : 'My Family Core',
    to    : withMemberId(EAppRoutes.MyFamilyCore),
  }, {
    icon  : <IconCalendar />,
    title : 'Schedule',
    to    : withMemberId(EAppRoutes.Schedule),
  }, {
    icon  : <IconLocate />,
    title : 'Locate',
    to    : withMemberId(EAppRoutes.Locate),
  } ];

  const secondaryMenu: IMenuItem[] = [ {
    title : 'Add Members',
    to    : EAppRoutes.AddMembers,
    roles : [ EFamilyAccountType.SuperAdmin ],
  }, {
    title : 'Manage Family',
    to    : EAppRoutes.ManageUsers,
    roles : adminRoles,
  }, {
    title : 'Manage Subscription',
    to    : "/settings/manage-account#changeSubscriptionSettings",
    roles : [ EFamilyAccountType.SuperAdmin ],
  } ];

  const statisticsMenu: IMenuItem[] = [{
    title : 'Admin Center',
    to    : EAppRoutes.AdminStatistics,
    roles : [ EFamilyAccountType.SuperAdmin ],
  } ];

  const bottomMenu: IMenuItem[] = [ {
    title : 'Privacy Policy',
    to    : '/terms',
  }, {
    title : 'Terms of Service',
    to    : '/terms',
  } ];

  const onItemSelected = useCallback(() => {
    if (isMobile) {
      toggleDrawer();
    }
  }, [ isMobile, toggleDrawer ]);

  const onTermsClick = () => {
    setTermsModalOpened(true);
    onItemSelected();
  };

  const onTermsServiceClick = () => {
    setTermsServiceModalOpened(true);
    onItemSelected();
  };

  const SidebarContent = () => (
    <Wrapper>
      <SidebarHeader>
        { isMobile ? (
          <IconButton
            color="inherit"
            onClick={toggleDrawer}
          >
            <IconClose />
          </IconButton>
        ) : (
          <SidebarLogo onClick={() => history.push(EAppRoutes.Dashboard)} />
        ) }
      </SidebarHeader>

      <Section>
        { primaryMenu.map(({ to, title, icon, roles }, index) => (
          <Authorized
            key={index}
            accountTypes={roles || allRoles}
          >
            <NavLink
              to={to}
              activeClassName="selected"
              onClick={onItemSelected}
            >
              <MenuItem button>
                { icon }
                { title }
              </MenuItem>
            </NavLink>
          </Authorized>
        )) }
      </Section>

      <Separator />

      <Section>
        <a href="https://www.thefamilycore.com/tips-and-tricks" target="__blank">
          <MenuItem button>Help</MenuItem>
        </a>

        { secondaryMenu.map(({ to, title, roles }, index) => (
          <Authorized
            key={index}
            accountTypes={roles || allRoles}
          >
            <NavLink
              to={to}
              activeClassName="selected"
              onClick={onItemSelected}
            >
              <MenuItem button>
                { title }
              </MenuItem>
            </NavLink>
          </Authorized>
        )) }

        { isAllowedForStatistics && statisticsMenu.map(({ to, title }, index) => (
          <Authorized
            key={index}
          >
            <NavLink
              to={to}
              activeClassName="selected"
              onClick={onItemSelected}
            >
              <MenuItem button>
                { title }
              </MenuItem>
            </NavLink>
          </Authorized>
        )) }

        <a href="https://thefamilycore.com/blog" target="__blank">
          <MenuItem button color="#fac05e">What's New!</MenuItem>
        </a>
      </Section>

      <BottomMenuSection>
        { bottomMenu.map(({ title, roles }, index) => (
          <Authorized
            key={index}
            accountTypes={roles || allRoles}
          >
            <MenuItem button onClick={title === 'Privacy Policy' ? onTermsClick : onTermsServiceClick}>
              { title }
            </MenuItem>
          </Authorized>
        )) }
      </BottomMenuSection>
    </Wrapper>
  );

  const SidebarMenuBlock = () => isMobile ? (
    <StyledSwipeableDrawer
      anchor="left"
      open={sidebarOpen}
      disableDiscovery={iOS}
      disableBackdropTransition={!iOS}
      onOpen={toggleDrawer}
      onClose={toggleDrawer}
    >
      <SidebarContent />
    </StyledSwipeableDrawer>
  ) : (
    <StyledDrawer
      open={sidebarOpen}
      variant="persistent"
      anchor="left"
    >
      <SidebarContent />
    </StyledDrawer>
  );

  return (
    <>
      <SidebarMenuBlock />
      <TermsModal
        open={termsModalOpened}
        onClose={() => setTermsModalOpened(false)}
      />
      <TermsServiceModal
        open={termsServiceModalOpened}
        onClose={() => setTermsServiceModalOpened(false)}
      />
    </>
  );
};

