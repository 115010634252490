import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import styled, { css }                               from 'styled-components';
import { Fab }                                       from '@material-ui/core';
import AddIcon                                       from '@material-ui/icons/Add';
import moment, { Moment }                            from 'moment';
import { Modal }                                     from '../../../shared/components/modals/Modal';
import { barberPoleFrames, flickerFrames }           from '../../../../styles/animations';
import { generateCalendar }                          from '../../../shared/components/modals/shared';
import { formatDate, formatDateWithZ, isCurrentDay } from '../../../shared/utils/commonFunctions';
import { IEvent }                                    from '../queries';
import { DayEvents }                                 from './DayEvents';
import { EventBadge, EventBadgeWrapper }             from './EventBadge';
import { useFetchFamilies }                          from '../../../shared/queries/family';
import { IFamily }                                   from '../../../Auth/shared/interfaces';
import { LimitedModal }                              from '../../../shared/components/modals/UploadTask/LimitedModal';

const CalendarWrapper = styled.div<{ loading?: number }>`
  display: flex;
  flex-direction: column;
  padding: 0 2px;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    padding: 0 20px;
  }

  ${ ({ loading }) => loading && css`
    pointer-events: none;

    ${ EventBadgeWrapper } {
      filter: grayscale(.5);
      opacity: .5;
      background-size: 30px 30px;
      background-image: linear-gradient(45deg,rgba(0,0,0,0.05) 25%,transparent 25%,transparent 50%,rgba(0,0,0,0.05) 50%,rgba(0,0,0,0.05) 75%,transparent 75%,transparent);
      animation: ${ barberPoleFrames } 0.5s linear infinite;

      & > div {
        animation: ${ flickerFrames } 2s infinite;
      }
    }
  ` }
`;

const AddEventButton = styled(Fab)`
  opacity: 0;
  position: absolute;
  bottom: 0;
  right: -5px;
  transition: opacity .3s ease;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    right: 0;
    position: relative;
  }
`;

const Day = styled.div<{ sameMonth?: boolean }>`&& {
  display: flex;
  flex: 1;
  flex-grow: 1;
  padding: 12px 0;
  height: 180px;
  min-width: 47px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-left: none;
  border-top: none;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: ${ ({ sameMonth }) => sameMonth ? '#000' : 'rgb(39 41 50 / 50%)' };

  &:hover {
    cursor: pointer;

    ${ AddEventButton } {
      opacity: 1;
    }
  }
}`;

const Week = styled.div`
  display: flex;
  border-left: 1px solid #ccc;
  overflow: hidden;

  &:first-child {
    ${ Day } {
      border-top: 1px solid #ccc;
    }
  }
`;

const DayName = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
`;

const DayHead = styled.div`
  display: flex;
  flex-direction: column;
`;

const DayContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DayContent = styled.div`
  flex: 1;
  display: flex;
  gap: 5px;
  width: 100%;
  max-height: 85px;
  flex-direction: column;
  padding: 8px 0;
  margin-bottom: 5px;
`;

const EventsMore = styled.div`
  color: #656565;
  font-size: 12px;
  padding: 0 4px;
  white-space: nowrap;
`;

const DayFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
  bottom: 10px;
  right: 10px;
  z-index: 3;
`;

const Date = styled.div<{ current?: boolean }>`
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: ${ ({ current }) => current ? '#fff' : 'inherit' };
  background-color: ${ ({ current }) => current ? '#e4572e' : 'transparent' };
`;

const ModalText = styled.p`
  color: #656565;
  font-weight: 700;
  font-size: 30px;
  text-align: justify;
  height: 100px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  margin: 39px 30px;

  ${ ({ theme: { breakpoints } }) => breakpoints.down('md') } {
      font-size: 22px;
    }

    ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
      font-size: 22px;
    }
`;

type TCalendar = Array<Array<Moment>>;

export interface IEventsMap {
  [date: string]: IEvent[];
}

interface ICalendarProps {
  current: Moment;
  events: IEventsMap;
  familyId?: string;
  loading?: boolean;
  data?   : Array<IEvent>;
  scheduleCount: number;

  onAdd(day: Moment): void;
}

export const Calendar: FC<ICalendarProps> = ({ current, events, onAdd, familyId, loading, scheduleCount }) => {
  const [ calendar, setCalendar ] = useState<TCalendar>([]);
  const [ anchorEl, setAnchorEl ] = useState<HTMLDivElement | null>(null);
  const [ selectedDay, setSelectedDay ] = useState<Moment | null>(null);
  const [ selectedEvents, setSelectedEvents ] = useState<IEvent[] | undefined | null>(null);
  const [ openFreeInfo, setOpenFreeInfo ] = useState(false);
  // const [ infoWindow, setInfoWindow ] = useState(false);

  const { data: families } = useFetchFamilies();

  const matchingFamily = families?.find((userFamily: IFamily) => userFamily.id === familyId);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>, day: Moment, list?: IEvent[]) => {
    if (list?.length) {
      setSelectedDay(day);
      const allEvents = list.filter((listItem) => !listItem.eventExceptionDays.includes(day.format().substring(0, day?.format().length - 1)));
      setSelectedEvents(allEvents);
      !!allEvents.length && setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    setCalendar(generateCalendar(current));
  }, [ current, loading ]);

  const getEventsByDate = (date: Moment): IEvent[] | undefined => {
    if (events) {
      const day = formatDateWithZ(date);

      const eventDate = formatDate(date.utc().toISOString());

      const dayEvents = events[eventDate]?.filter((eventItem) => {
        if (eventItem?.eventExceptionDays) {
          if (!eventItem?.eventExceptionDays.includes(day)) {
            return eventItem;
          }
        } else {
          return eventItem;
        }
      });

      return dayEvents;
    }
  };

  const getEventDuration = (eventId: string | undefined, day: moment.Moment) => {
    let lastDayOfEvent: string;

    const sameEvents = Object.keys(events)
      .sort()
      .filter((eventKey: string) => {
        const sameEvent = events[eventKey].find(event => (event.id === eventId) && moment(event.recursStartDate).isSameOrAfter(day, 'day'));
        if (sameEvent?._nextDayEventIndex === -1 && !lastDayOfEvent) {
          lastDayOfEvent = eventKey;
        }
        return sameEvent;
      });

    return sameEvents.slice(0, sameEvents.findIndex(eventDate => eventDate === lastDayOfEvent) + 1).length;
  };

  return useMemo(() => (
    <CalendarWrapper loading={+!!loading}>
      { calendar.map((week, weekNumber) => (
        <Week key={weekNumber}>
          { week.map((day, dayNumber) => {
            const dayEvents = getEventsByDate(day);
            const currentLocalDay = moment().local();

            const eventsList = dayEvents?.slice(0, 3)?.map((event:IEvent, index:number) =>  {
              const certaineDay = formatDateWithZ(day);
              const eventExceptionDays = event?.eventExceptionDays;
              const isHidden = event?.eventExceptionDays?.includes(certaineDay);

              if (eventExceptionDays) {
                return (
                  <Fragment key={index}>
                    {
                      !isHidden && (
                        <EventBadge
                          key={index}
                          day={formatDateWithZ(day)}
                          event={event}
                          eventDuration={getEventDuration(event.id, day)}
                        />
                      )
                    }
                  </Fragment>
                );
              }

              return (
                <EventBadge
                  key={index}
                  day={formatDateWithZ(day)}
                  event={event}
                  eventDuration={getEventDuration(event.id, day)}
                />
              );
            });
            return (
              <Day
                key={dayNumber}
                className="event-date-square"
                sameMonth={day.isSame(current, 'month')}
              >
                <DayHead>
                  { !weekNumber && (
                    <DayName>{ day.format('ddd') }</DayName>
                  ) }

                  <Date current={isCurrentDay(currentLocalDay, day)}>
                    { day.format('D')} <br />
                  </Date>
                </DayHead>

                <DayContentWrapper onClick={(event) => handleClick(event, day, dayEvents)}>
                  <DayContent>
                    { eventsList }
                  </DayContent>

                  <EventsMore>
                    { dayEvents && dayEvents?.length > 3 && `${ dayEvents?.length - 3 } more...` }
                  </EventsMore>
                </DayContentWrapper>

                <DayFooter>
                  <AddEventButton
                    size="small"
                    color="secondary"
                    aria-label="add"
                    onClick={() => {
                      const formattedDate = day.startOf('day').format('YYYY-MM-DD');
                      if (scheduleCount === 15 && !matchingFamily?.isActivePayment) {
                        setOpenFreeInfo(true);
                      } else {
                        onAdd(moment(formattedDate));
                      }
                    }}
                  >
                    <AddIcon />
                  </AddEventButton>
                </DayFooter>
              </Day>
            );
          }) }
        </Week>
      )) }

      <LimitedModal
        openFreeInfo={openFreeInfo}
        entityName="Events"
        setOpenFreeInfo={setOpenFreeInfo}
      />

      {/* <Modal
        hideCloseIcon={false}
        open={infoWindow}
        dialogWidth="500px"
        onClose={() => setInfoWindow(false)}
      >
        <ModalText>Go to the next/past month page to create events</ModalText>
      </Modal> */}

      {!!selectedEvents?.length &&
      ( <DayEvents
        events={selectedEvents}
        familyId={familyId}
        day={selectedDay}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />)}
    </CalendarWrapper>
  ), [ calendar, open, loading, events, openFreeInfo ]);
};
