import { Badge, Button, DialogActions }     from '@material-ui/core';
import { FC, useContext }                   from 'react';
import styled                               from 'styled-components';
import { ReactComponent as BigAlertIcon }   from '../../../../images/icons/rounded/icon-alert-big.svg';
import { ReactComponent as SmallAlertIcon } from '../../../../images/icons/rounded/icon-alert-small.svg';
import { StyledDialogContent }              from '../../../shared/components/modals/AlertDialog';
import { Modal }                            from '../../../shared/components/modals/Modal';
import { isSuperAdmin }                     from '../../../shared/functions';
import { TextBrandColor }                   from '../../../shared/styledComponents';
import { formatDate, generateInitials }     from '../../../shared/utils/commonFunctions';
import AuthorizationContext                 from '../../../shared/utils/withAuthorization/authorizationContext';
import { IFamilyMember }                    from '../../../shared/utils/withAuthorization/withAuthorization';
import { FamilyMemberCircle }               from '../../Dashboard/components/FamilyMembers/FamilyMemberCircle';

interface IDeleteUserDialogProps {
  submitButtonDisabled?: boolean;
  user?: IFamilyMember;
  nextPaymentDate?: string;
  isCancelSubscription?: boolean;
  onClose: () => void;
  handleSubmit: () => void;
}

const DeleteUserDialogActions = styled(DialogActions)`
  .MuiButtonBase-root:first-child {
    color: #e4572e;
    border-color: #e4572e;
  }
`;

const DeleteUserModal = styled(Modal)`
  text-align: center;
  font-family: Jost;

  .MuiButtonBase-root,
  .MuiIconButton-root {
    z-index: 1;
  }
  
  .MuiDialogActions-root {
    flex-wrap: wrap;
    justify-content: center;
    padding: 13px 32px 28px;
    
    ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
      padding: 13px 32px 38px;
    }

    ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
      padding: 13px 32px 56px;
    }

    .MuiButtonBase-root {
      margin: 0 0 10px 0;
      width: 100%;

      ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
        margin: 0;
        width: auto;

        &:last-child {
          margin-left: 8px;
        }
      }
    }
  }
`;

const MainText = styled.div`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.14px;
  padding-top: 16px;
  line-height: 1.21;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    line-height: 1.31;
    font-size: 30px;
  }
`;

const Description = styled.div`
  font-size: 16px;
  letter-spacing: 0.64px;
  padding: 18px 10px 10px;
  line-height: normal;
`;

const DeleteUserDialogContent = styled(StyledDialogContent)`
  align-items: center;
  display: flex;
  flex-direction: column;

  svg {
    width: 73px;
    height: 73px;
    overflow: visible;
  }

  &.MuiDialogContent-root {
    padding: 48px 32px 0;

    ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
      padding: 38px 52px 0;
    }

    ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
      padding: 68px 52px 0;
    }
  }

  .MuiBadge-badge {
    height: 33px;
    width: 46px;
    top: 18px;
  }

  .MuiBadge-root {
    div:last-child {
      border: 2px solid #e4572e;
    }
  }
`;

const Details = styled(Description)`
  font-size: 14px;
  letter-spacing: 0.4px;
  padding: 13px 10px 10px;
`;

export const DeleteUserDialog: FC<IDeleteUserDialogProps> = ({
  submitButtonDisabled,
  user,
  nextPaymentDate = null,
  isCancelSubscription,
  onClose,
  handleSubmit,
}) => {
  const currentUser = useContext(AuthorizationContext);

  const userDeletionContent = (
    <>
      <Badge
        overlap="rectangular"
        badgeContent={<SmallAlertIcon />}
        aria-controls="user-menu"
        aria-haspopup="true"
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        <FamilyMemberCircle
          width="68px"
          fontSize="24px"
          color={user?.color}
          image={user?.avatar?.compactPath}
          initials={generateInitials(user?.firstName, user?.lastName)}
        />
      </Badge>

      <MainText>
        Are you sure you want to <TextBrandColor>close this user's account</TextBrandColor>?
      </MainText>

      <Description>
        { user?.hasEmail && "This will take effect immediately. Before closing, warn the user to download all important documents and copy any information. Your Super Admin, Admin, and this user will be notified via email." }
      </Description>
    </>
  );

  const infoCancelSubscription = (
    <>
      <MainText>
        <b>Are you sure you want to delete this account and data?</b>
      </MainText>

      <Description>
        If you are <b>Super Admin</b>, this action will cancel your subscription and delete your entire
        Family account and all of its users. If you are an <b>Admin</b> or <b>Member</b>, this action will delete
        only your account and association with this Family; if you're a part of other Families,
        you will still have access to them.
      </Description>

      <Description>
        If you are a <b>Premium Plan</b> account, you will have until the end of your subscription period to
        download or save any important information. If you are a <b>Free Plan</b> account, you will have 1-2
        days to download or save any important information.
      </Description>

      <Description>
        You have a small window of time to use the 'Restore Account' button if you change your mind,
        but once the time has passed, you will no longer have access to the account and the data will
        be deleted.
      </Description>
    </>
  );

  const selfDeletionContent = (
    <>
      <BigAlertIcon />
      { infoCancelSubscription }
    </>
  );

  return (
    <DeleteUserModal
      open
      dialogWidth="423px"
      onClose={onClose}
    >
      <DeleteUserDialogContent>
        { (!user || (user.id === currentUser?.data?.id)) ? selfDeletionContent : userDeletionContent }
      </DeleteUserDialogContent>

      <DeleteUserDialogActions>
        <Button
          color="primary"
          variant="outlined"
          disabled={submitButtonDisabled}
          onClick={() => handleSubmit()}
        >
          Delete Account
        </Button>

        <Button
          color="primary"
          type="submit"
          variant="contained"
          onClick={onClose}
        >
            Not Right Now
        </Button>
      </DeleteUserDialogActions>

    </DeleteUserModal>
  );
};
