import { Box, IconButton, ListItem, ListItemText, Popover }                   from '@material-ui/core';
import MoreHorizIcon                                                          from '@material-ui/icons/MoreHoriz';
import React, { FC, useRef }                                                  from 'react';
import styled, { css }                                                        from 'styled-components';
import { MenuAnchor }                                                         from '../../Auth/shared/styles';
import { Flex, StyledDivider }                                                from '../../shared/styledComponents';
import { IFamilyMember }                                                      from '../../shared/utils/withAuthorization/withAuthorization';
import { AssignedMembers }                                                    from '../Dashboard/components/FamilyMembers/AssignedMembers';
import { ListItemContent, ListItemWrapper, RecordDescription, RecordDetails } from './ActivityItems';
import { ReactComponent as GoogleCalendar }                                   from '../../../images/icons/google.svg';

export const RecordLabel = styled.span`
  width: 100%;
  display: flex;
  color: #272932;
  font-size: 14px;
  line-height: 16px;
`;

export const RecordMessage = styled.span`${ ({ theme: { breakpoints } }) => css<{ ellipsis?: boolean }>`&& {
  display: block;
  font-size: 13px;
  line-height: 1.38;
  color: #5071bb;
  margin-bottom: 2px;
  font-family: 'Lato';
  font-weight: 600;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${ breakpoints.up('sm') } {
    font-size: 16px;
  }
}` }`;

const RecordContent = styled.span`
  display: flex;
  align-items: center;
`;

const ActivityContentLayer = styled.div<{ width: string }>`
  width: ${ ({ width }) => width };
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex: 1;

  .MuiListItemText-root {
    width: 100%;
  }
`;

const ActivityRecordWrapper = styled.div<{ disabled?: boolean }>`
  width: 100%;
  display: block;
  pointer-events: ${ ({ disabled }) => disabled && 'none' };
`;

const MenuWrapper = styled.span`
  min-width: 0;
`;

const ActivityActionsPopover = styled(Popover)`
  .MuiPaper-root {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    padding: 8px 0;
    width: 100px;
    background-color: #fafafa;
  }

  .MuiListItem-root {
    padding: 4px 11px;
  }
`;

const GoogleCalendarIcon = styled(GoogleCalendar)`
  height: 15px;
  width: 15px;
  margin-right: 10px;
`;

interface IActivityRecordPreview {
  icon?: JSX.Element;
  label?: string | JSX.Element;
  isLast?: boolean;
  isGoogleEvent?: boolean;
  details?: JSX.Element;
  content?: JSX.Element;
  showMenu?: boolean;
  disabled?: boolean;
  assigned?: string[];
  menuItems?: JSX.Element;
  description?: string;
  familyMembers?: IFamilyMember[];
  truncateMessage?: boolean;

  onClick?(): void;
}

export const ActivityRecordPreview: FC<IActivityRecordPreview> = ({
  icon,
  label,
  isGoogleEvent=false,
  isLast,
  details,
  content,
  showMenu,
  disabled,
  assigned,
  menuItems,
  description = true,
  familyMembers,
  truncateMessage,
  onClick,
}) => {
  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const menuAnchorRef = useRef(null);

  const handleClick = () => {
    setAnchorEl(menuAnchorRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ActivityRecordWrapper disabled={disabled}>
      <ListItem alignItems="flex-start">
        <ListItemWrapper>
          <ListItemContent>
            <Flex
              flex={0}
              align="center"
              onClick={onClick}
            >
              { icon }
            </Flex>

            <ActivityContentLayer
              width={icon ? 'calc(100% - 95px)' : '100%'}
              onClick={onClick}
            >
              <ListItemText
                secondary={(
                  <RecordDescription>
                    <span>
                      <RecordLabel>
                        { label }
                      </RecordLabel>

                      <RecordMessage ellipsis={truncateMessage}>
                        {isGoogleEvent && (
                          <GoogleCalendarIcon />
                        )}
                        { description }
                      </RecordMessage>

                      <RecordDetails>
                        { details }
                      </RecordDetails>
                    </span>

                    { content && (
                      <RecordContent>
                        { content }
                      </RecordContent>
                    ) }
                  </RecordDescription>
                )}
              />

              <AssignedMembers
                noWrap
                data={familyMembers}
                assigned={assigned}
                justify="flex-end"
              />

              { !!assigned?.length && <Box height={10} /> }
            </ActivityContentLayer>

            <MenuWrapper>
              { showMenu && !isGoogleEvent && (
                <IconButton
                  size="small"
                  aria-haspopup="true"
                  aria-controls="activity-record-menu"
                  onClick={handleClick}
                >
                  <MenuAnchor
                    ref={menuAnchorRef}
                    color="#fff"
                    top="30px"
                    right="100px"
                  />
                  <MoreHorizIcon />
                </IconButton>
              ) }
            </MenuWrapper>
          </ListItemContent>
        </ListItemWrapper>
      </ListItem>

      { !isLast ? (
        <StyledDivider mt={10} />
      ) : null }

      <ActivityActionsPopover
        id="family-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        { menuItems }
      </ActivityActionsPopover>
    </ActivityRecordWrapper>
  );
};

