import { IconButton }                      from '@material-ui/core';
import moment, { Moment }                   from 'moment';
import React,
{ FC, useEffect, useMemo, useState }        from 'react';
import { useHistory, useParams }            from 'react-router-dom';
import { FastField, Formik, Form }          from 'formik';
import axios                                from 'axios';
import styled                               from 'styled-components';
import { ReactComponent as IconArrowLeft }  from '../../../images/icons/calendar-chevron-left.svg';
import { ReactComponent as IconArrowRight } from '../../../images/icons/calendar-chevron-right.svg';
import { Layout }                           from '../../Layout/Layout';
import { UploadEventModal }                 from '../../shared/components/modals/UploadEvent/UploadEventModal';
import { MuiButton }                        from '../../shared/components/MuiButton';
import { useFetchSelectedFamily }           from '../../shared/components/SelectFamily/queries';
import { useFetchFamilies }                 from '../../shared/queries/family';
import { DateRangeField } from '../../shared/formFields/DateRangeField';

import {
  ReportsBlockWrapper,
  UploadFormLabel,
} from '../../shared/components/documents/styles';
import { EAppRoutes }                       from '../../shared/constants/constants';
import { getCorrectUserTime, queryParams }  from '../../shared/functions';
import { ResponsivePageTitle }              from '../../shared/styledComponents';
import { formatDate }                       from '../../shared/utils/commonFunctions';
import { LimitedModal }                     from '../../shared/components/modals/UploadTask/LimitedModal';
import { SummaryPageHead }                  from '../MyFamilyCore/shared/SummaryPageHead';
import { Calendar, IEventsMap }             from './components/Calendar';
import { generateRandomSchedule }           from './helpers';
import {
  IEvent,
  useFetchSchedulePage,
}                                           from './queries';
import { IFamily }                          from '../../Auth/shared/interfaces';

const SchedulerWrapper = styled.div`
  background-color: #f7fafd;
  padding: 16px 0;
  margin: 0 -16px;
  width: calc(100% + 32px);

  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
    margin: 0 -32px;
    width: calc(100% + 64px);
  }
`;

const CalendarArrows = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;

  svg {
    color: #547cd3;
  }
`;

const BlockWrapper = styled.div`
  display: flex;

  ${ ({ theme: { breakpoints } }) => breakpoints.down('xs') } {
    margin-bottom: 20px;
  }
`;

const CalendarHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 16px;
  justify-content: space-between;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    padding: 0 20px;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    padding: 0 20px;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.down('md') } {
    padding: 0 20px;
    display: flex;
  }

  ${ ({ theme: { breakpoints } }) => breakpoints.down('xs') } {
    padding: 0 20px;
    display: block;
  }
`;

const CustomIconButton = styled(IconButton)`
  padding: 0;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    padding: 5px;
  }
`;

const CurrentMonthTitle = styled(ResponsivePageTitle)`
  font-size: 22px;
  margin: ${({ margin }) => margin };

  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    font-size: 28px;
  }
`;

const TodayButton = styled(MuiButton)`
  width: 62px;

  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    width: auto;
  }
`;

const FastFieldWrapper = styled(FastField)`
  border-radius: 6px;
`;

const FormWrapper = styled(Form)`
  display: flex;

  & div {
    & div {
      border-radius: 6px;
    }
  }
`;

const GoogleCalendarButton = styled(MuiButton)`
  height: 40px;
  margin-left: 10px;
`;

export const Schedule: FC = () => {
  const [ current, setCurrent ] = useState<Moment>(moment.utc().set({
    hours       : 0,
    minute      : 0,
    second      : 0,
    millisecond : 0,
  }));
  const [ uploadEventModalOpen, setUploadEventModalOpen ] = useState<Moment | null>(null);
  const [ eventsMap, setEventsMap ] = useState({});
  const params = queryParams();
  const familyMemberId = params?.id as string;
  const { eventMonth: selectedEventMonth } = useParams<{ eventMonth: string }>();
  const history = useHistory();

  const month = +current.format('M');
  const year = +current.format('YYYY');

  const { data: family } = useFetchSelectedFamily();
  const { data: schedule, isLoading: loadingSchedule } = useFetchSchedulePage(family?.id, familyMemberId, month, year);
  const { data: families } = useFetchFamilies();

  const [ dataForLoading, setDataForLoading ] = useState<IEvent[]>([]);
  const [ openFreeInfo, setOpenFreeInfo ] = useState(false);

  const matchingFamily = families?.find((userFamily: IFamily) => userFamily.id === family?.id);

  const selectPreviousMonth = () => {
    setCurrent(current.clone().subtract(1, 'month'));
    if (selectedEventMonth) {
      history.push(`${ EAppRoutes.Schedule }?id=${ familyMemberId }`);
    }
  };

  const updatedSchedule = useMemo(() => (schedule ? schedule.events : []).map((item: any) => {
    const updatedDate = getCorrectUserTime(item?.recursStartTime, -item?.offset);

    return {
      ...item,
      recursStartTime: item?.isImportedFromGoogle ? item?.recursStartTime : updatedDate,
    };
  }), [schedule]);

  const selectNextMonth = () => {
    setCurrent(current.clone().add(1, 'month'));
    if (selectedEventMonth) {
      history.push(`${ EAppRoutes.Schedule }?id=${ familyMemberId }`);
    }
  };

  const fetchGoogleOAuthRedirect = async (startDate: string, endDate: string) => {
    try {
      const response = await axios.get(`/api/v1/GoogleOAuth/redirect/${family?.id}`, {
        params: {
          startDate : startDate,
          endDate   : endDate,
        },
      });
      const redirectUrl = response.data;
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    } catch (error) {
      console.error('Error on receiving a redirect link:', error);
    }
  };

  const handleRedirect = (startDate: string, endDate: string) => {
    if (matchingFamily?.isActivePayment === false) {
      setOpenFreeInfo((prev) => !prev);
    } else {
      fetchGoogleOAuthRedirect(startDate || '', endDate || '');
    }
  };

  useEffect(() => {
    if (selectedEventMonth) {
      const eventMonthNumber = parseInt(moment().month(selectedEventMonth).format('M')) - 1;
      setCurrent(current.clone().set('month', eventMonthNumber));
    }
  }, [ selectedEventMonth ]);

  useEffect(() => {
    setDataForLoading(generateRandomSchedule(current));
  }, [ current.toISOString(), window.location.href ]);

  useEffect(() => {
    const source = updatedSchedule || dataForLoading;

    if (source?.length) {
      const dateFormat = 'MM/DD/YYYY';
      const _eventsDateMap: IEventsMap = {};

      // Generating a map of event objects for memory-effective access.
      source?.forEach((event: any) => {
        const eventDate = formatDate(moment.utc(event.date).toISOString());
        const existingEvents = _eventsDateMap[eventDate];
        const sortedEvents = existingEvents && [...existingEvents, event]
          .sort((a, b) => {
            if (a?.recursStartTime && b?.recursStartTime === null) {
              return 1;
            } else if (a?.recursStartTime && b?.recursStartTime && a?.recursStartTime > b?.recursStartTime) {
              return 1;
            } else  return -1;
          });

        _eventsDateMap[eventDate] = sortedEvents || [event];
      });

      const prevDate = (day: string) => moment.utc(day, dateFormat).subtract(1, 'd').format(dateFormat);
      const nextDate = (day: string) => moment.utc(day, dateFormat).add(1, 'd').format(dateFormat);

      const prevDayEvents = (day: string) => _eventsDateMap[prevDate(day)];
      const nextDayEvents = (day: string) => _eventsDateMap[nextDate(day)];

      const eventIndex = (_event: IEvent, source: IEvent[]) => source?.findIndex((evt) => evt.id === _event.id);

      Object.keys(_eventsDateMap).forEach(key => {
        // _nextDayEventIndex and _prevDayEventIndex are required for sorting here.
        const dayEvents = _eventsDateMap[key]?.map((_event) => ({
          ..._event,
          _prevDayEventIndex : eventIndex(_event, prevDayEvents(key)),
          _nextDayEventIndex : eventIndex(_event, nextDayEvents(key)),
        }));

        const allDayEvents = dayEvents?.filter(evt => evt.allDay);
        const nonAllDayEvents = dayEvents?.filter(evt => !evt.allDay);

        _eventsDateMap[key] = allDayEvents.sort((a, b) => {
          const currentIndex = eventIndex(a, allDayEvents);
          if (a._prevDayEventIndex !== -1 && a._nextDayEventIndex !== -1 &&
            b._prevDayEventIndex !== -1 && b._nextDayEventIndex !== -1) {
            if (a._prevDayEventIndex !== currentIndex) {
              return -1;
            }
          }

          return 1;
        }).sort((a, b) => new Date(a.creationTime).getTime() - new Date(b.creationTime).getTime())
          .concat(nonAllDayEvents);
      });

      // Leaving _nextDayEventIndex and _prevDayEventIndex only for the events on the same height.
      Object.keys(_eventsDateMap).forEach(key => {
        _eventsDateMap[key] = _eventsDateMap[key]?.map((_event, index) => {
          const _prevDayEventIndex = eventIndex(_event, prevDayEvents(key));
          const _nextDayEventIndex = eventIndex(_event, nextDayEvents(key));

          return {
            ..._event,
            _prevDayEventIndex : _prevDayEventIndex === index ? _prevDayEventIndex : -1,
            _nextDayEventIndex : _nextDayEventIndex === index ? _nextDayEventIndex : -1,
          };
        });
      });
      setEventsMap(_eventsDateMap);
    } else {
      setEventsMap({});
    }
  }, [ schedule, dataForLoading, loadingSchedule ]);

  const Page = (
    <>
      <SummaryPageHead title="Schedule">
        {/*<GoogleCalendarButton>*/ }
        {/*  <IconGooglePlus/>Google Calendar*/ }
        {/*</GoogleCalendarButton>*/ }
      </SummaryPageHead>

      <SchedulerWrapper>
        <CalendarHeader>
          <BlockWrapper>
            <TodayButton
              disabled={current.isSame(moment.utc(), 'month')}
              variant="outlined"
              onClick={() => setCurrent(moment.utc())}
            >
              Today
            </TodayButton>

            <CalendarArrows>
              <CustomIconButton onClick={selectPreviousMonth}>
                <IconArrowLeft />
              </CustomIconButton>

              <CustomIconButton onClick={selectNextMonth}>
                <IconArrowRight />
              </CustomIconButton>
            </CalendarArrows>

            <CurrentMonthTitle margin="0">
              { current.format('MMMM YYYY') }
            </CurrentMonthTitle>
          </BlockWrapper>

          <ReportsBlockWrapper>
            <Formik
              initialValues={{ startDate: '', endDate: '' }}
              onSubmit={(values) => {

                handleRedirect(values.startDate, values.endDate);
              }}
            >
              {({ handleSubmit }) => (
                <FormWrapper>
                  <FastFieldWrapper
                    startName="startDate"
                    endName="endDate"
                    isSyncEnabled={true}
                    component={DateRangeField}
                  />

                  <GoogleCalendarButton
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    Sync with Google Calendar
                  </GoogleCalendarButton>
                </FormWrapper>
              )}
            </Formik>
          </ReportsBlockWrapper>
        </CalendarHeader>

        <Calendar
          loading={!schedule || loadingSchedule}
          data={schedule?.events ?? []}
          scheduleCount={schedule?.totalCount}
          familyId={family?.id}
          events={eventsMap}
          current={current}
          onAdd={(day) => setUploadEventModalOpen(day)}
        />
      </SchedulerWrapper>

      <UploadEventModal
        eventDate={uploadEventModalOpen?.format('YYYY-MM-DDTHH:mm:ss.SSS')}
        familyId={family?.id}
        open={!!uploadEventModalOpen}
        schedule={schedule?.events ?? []}
        onClose={() => setUploadEventModalOpen(null)}
      />

      <LimitedModal
        openFreeInfo={openFreeInfo}
        entityName={'GoogleCalendar'}
        setOpenFreeInfo={setOpenFreeInfo}
      />
    </>
  );

  return (
    <Layout>
      { Page }
    </Layout>
  );
};
