// @ts-ignore
import DateRangePicker                              from '@wojtekmaj/react-daterange-picker';
import moment                                       from 'moment';
import { FC, useState }                             from 'react';
import { ReactComponent as IconDate }               from '../../../../images/icons/icon-date.svg';
import { IconRemove }                               from '../../../modules/MyFamilyCore/shared/styles';
import { DashedButtonIconLabel, DatePickerWrapper } from '../../styledComponents';

interface ISelectDateRangeProps {
  endDate?: string;
  startDate?: string;
  isSyncEnabled?: boolean;

  onRangeChange(value: Date[] | null): void;
}

export const SelectDateRange: FC<ISelectDateRangeProps> = (props) => {
  const [ isOpen, setIsOpen ] = useState(false);

  const { onRangeChange, isSyncEnabled } = props;
  const startDate = props.startDate ? moment(props.startDate) : null;
  const endDate = props.endDate ? moment(props.endDate) : null;

  const sameMonth = endDate?.month() === startDate?.month();
  const sameDate = endDate?.date() === startDate?.date();
  const startDateFormatted = startDate && startDate.format('MMM D');
  const endDateFormatted = endDate && endDate.format(sameMonth ? 'D' : 'MMM D');

  const displayText = isSyncEnabled ? 'Choose Sync Date' : 'Choose a date';

  let formattedRange = `${ startDateFormatted }`;

  if (startDate && endDate) {
    if (startDateFormatted !== endDateFormatted && !sameDate) {
      formattedRange += ' - ' + endDateFormatted;
    }
  }

  const onClose = (event: any) => {
    event.stopPropagation();
    onRangeChange(null);
  };

  return (
    <>
      <DatePickerWrapper
        empty={!startDate}
        onClick={() => setIsOpen(!isOpen)}
      >
        <DashedButtonIconLabel>
          <IconDate />
          { startDate ? formattedRange : '' }
        </DashedButtonIconLabel>

        { startDate ? <IconRemove onClick={onClose} /> : displayText }
      </DatePickerWrapper>

      <DateRangePicker
        key={isOpen}
        format={'MMM dd'}
        isOpen={isOpen}
        locale="en-US"
        formatShortWeekday={(_locale: string, date: Date) => moment(date).format('dd')}
        calendarIcon={<IconDate />}
        clearIcon={<IconRemove />}
        openCalendarOnFocus={false}
        value={[startDate, endDate]}
        onChange={onRangeChange}
        onCalendarClose={() => setIsOpen(false)}
      />
    </>
  );
};
