import styled                from 'styled-components';
import { Typography }        from '@material-ui/core';
import DialogContent         from '@material-ui/core/DialogContent';
import DialogContentText     from '@material-ui/core/DialogContentText';
import { TextBrand }         from '../styledComponents';
import { UploadDialogTitle } from './modals/styles';

const TermsText = styled(DialogContentText)`
  font-family: 'Lato';
  font-size: 16px;
  color: #272932;
  line-height: 26px;

  ul {
    list-style: disc;
    margin-left: 30px;
  }
`;

const Content = styled(DialogContent)`
  word-break: break-word;
  
  ${ TextBrand } {
    margin-bottom: 5px;
  }
`;

export const PrivacyContent = () => (
  <>
    <UploadDialogTitle>
      <p>PRIVACY POLICY</p>
      <TermsText>Last Updated: 03/27/2025</TermsText>
    </UploadDialogTitle>

    <Content>

      <TermsText>
        Family Core, LLC and its affiliates ("Family Core" or "We") know that you care about how the
        information we receive from you is used and shared, and we take your privacy seriously. This
        Privacy Policy describes Family Core's privacy practices in relation to information that we collect
        through our websites, applications, application programming interfaces, and online services
        (collectively, the "Domains"), through our social media pages (collectively, the "Social Media
        Pages"), and through our HTML-formatted email messages (collectively, including the Domains
        and the Social Media Pages, the "Sites"). This Privacy Policy also describes Family Core's
        privacy practices in relation to information that Family Core shares with its partners,
        subsidiaries, and affiliates.
      </TermsText>

      <TermsText>
        "You" or a "user" refers to any individual who visits or otherwise uses the Sites. By using the
        Sites, you are accepting and agreeing to the practices outlined in this Privacy Policy. If you do
        not agree with the data practices described in this Privacy Policy, you should not use our Sites
        or our services.
      </TermsText>

      <TextBrand>1. What Types of Information Does Family Core Receive From its Users?</TextBrand>

      <TermsText>
        <ul>
          <li>
            <b>Personal Information.</b> "Personal Information" is information that relates to an
            identified or identifiable individual, such as an individual's name, address,
            telephone number, email address, IP Address, cookie identifier(s), data revealing
            religious affiliation, and location information. It can also include transaction
            information, such as payment information, records of products or services
            purchased, and billing details. It can also include content you post on our Sites,
            such as text, images, photographs, videos, messages, comments, or any other
            kind of content if the content, alone or in connection with other information,
            reveals the identity of an individual. If you submit any Personal Information
            relating to other individuals through or to our Sites, you represent that you have
            the authority to do so and to permit us to use the information in accordance with
            this Privacy Policy.
          </li>
          <li>
            <b>Google Calendar and OAuth.</b> The following describes how The Family Core shares information when you use our services that integrates with GoogleOAuth.
            When you use GoogleOAuth to access our services we collect your name, email address, profile picture and any other
            information you choose to make available through your google account. We use the information collected through Google
            OAuth to authenticate your identity, provide and improve our services and communicate with you about our services.
          </li>
          <li>
            <b>Other Information.</b> "Other Information" is any information that does not relate
            directly to an individual's identity. This might include, for instance, information
            about how you use our Sites and interact with us, such as registrations you make
            through our Sites. We use this information to facilitate our operation of the Sites
            and for other purposes described below.
          </li>
          <li>
            <b>Aggregated Information:</b> "Aggregated Information" may draw from users'
            Personal Information or Other Information, but it does not personally identify you
            or any other user of the Sites in its aggregate form. (For example, we may
            aggregate Personal Information to calculate the percentage of our users who
            have a particular telephone area code.)
          </li>
        </ul>
      </TermsText>

      <TextBrand>2. How Does Family Core Collect Information?</TextBrand>

      <TermsText>
        <b>Personal Information.</b> We may collect Personal Information in a variety of ways, including:
      </TermsText>

      <TermsText>
        <ul>
          <li>
            <b>Through our Sites:</b> We may collect Personal Information through our Sites, e.g., when you contact us,
            subscribe to our email newsletter, download content, or register to use our Sites.
          </li>
          <li>
            <b>Offline:</b> We may collect Personal Information from you offline, such as during phone calls with sales
            representatives or when you contact customer service.
          </li>
          <li>
            <b>Through You:</b> We may collect information when you voluntarily provide it, such as when you respond to
            surveys, participate in market research activities, report a problem with our Sites, receive customer support,
            or otherwise correspond with us.
          </li>
          <li>
            <b>Information From Other Sources:</b> In order to enhance our ability to provide relevant marketing, offers,
            and services to you, we may obtain information about you from other sources such as joint marketing partners,
            social media platforms, as well as from other third parties. Some of our Sites include interfaces that allow you
            to connect with social networking sites (each an "SNS"). If you connect to an SNS through our Site, you authorize
            us to access, use, and store the information that you agreed the SNS could provide to us based on your settings
            on that SNS. We will access, use, and store that information in accordance with this Privacy Policy. You can revoke
            our access to the information you provide in this way at any time by amending the appropriate settings from within
            your account settings on the applicable SNS.
          </li>
        </ul>
      </TermsText>

      <TermsText>
        <b>Other Information.</b> We may collect Other Information in a variety of ways, including:
      </TermsText>

      <TermsText>
        <ul>
          <li>
            <b>Through Your Browser or Device:</b> Certain information is collected by most browsers or automatically through
            your device, such as your computer type (Windows or Macintosh), screen resolution, operating system name and version,
            device manufacturer and model, language, Internet browser type and version, and the name(s) of the Family Core Sites
            you are using.
          </li>
          <li>
            <b>Through Cookies and Other Similar Technologies:</b> Cookies and other similar technologies may be used to collect
            Other Information. We use essential cookies to provide you with services available through our Sites. We use analytics
            and performance cookies to collect information about traffic to our Sites and how users use our Sites. We use social
            media cookies when you share information using a social media site sharing button or action. We may use third parties
            to display advertisements on other websites that may be of interest to you, based on information collected about your
            use of our Sites. To do so, these companies may place or recognize a unique cookie on your browser (including through
            the use of pixel tags and web beacons). Family Core does not recognize automated browser signals regarding tracking
            mechanisms, which may include 'do not track' instructions.
          </li>
          <li>
            <b>Through Google Analytics:</b> Family Core uses Google Analytics. Google Analytics is a web analytics service
            provided by Google Inc. (<b>"Google"</b>) that uses cookies and similar technologies to analyze how users use domains.
            The information generated about domain usage is transmitted to Google in the U.S. This information is used to evaluate
            visitors' use of the Domains, compile statistical reports on Domain activity, and provide other services related to the
            Sites. Google may also collect information about Domain visitors' use of other websites. For more information about
            Google Analytics, or to opt out of Google Analytics, please go to:
            <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout.</a>
          </li>
        </ul>
      </TermsText>

      <TermsText>
        <b>Disabling cookies.</b> Cookies are pieces of data that are used to identify and remember your computer and improve your browsing experience when
        you use our Sites. You can typically remove or reject cookies via your browser settings. In order to do this, follow the
        instructions provided by your browser (usually located within the "settings," "help," "tools," or "edit" facility). Many
        browsers are set to accept cookies until you change your settings. Further information about cookies, including how to see
        what cookies have been set on your computer or mobile device and how to manage and delete them, visit <a href="www.allaboutcookies.org">www.allaboutcookies.org. </a>
        If you remove or reject our cookies, you may experience some inconvenience in your use of our Sites. For example, we may not
        be able to recognize your computer or mobile device, and you may need to log in every time you visit our Sites.
      </TermsText>

      <TermsText>
        <b>Pixel tags.</b> We may use pixel tags (which are also known as web beacons or clear GIFs) on our
        Sites to track the actions of users on our Sites. Unlike cookies, which are stored on the hard
        drive of your computer or mobile device, pixel tags are embedded invisibly on webpages. Pixel
        tags measure the success of our marketing campaigns and compile statistics about usage of
        the Sites, so that we can manage our content more effectively. The information we collect using
        pixel tags is not linked to our users’ Personal Information.
      </TermsText>

      <TermsText>
        <b>Third Party Pixel Tags and Cookies.</b> When you visit our website, log in, register, or open an
        email from us, cookies, pixel tags, and similar technologies may be used by our online data
        partners or vendors to associate these activities with information they or others have about you,
        including your email address. We (or service providers on our behalf) may then send
        communications and marketing to these email addresses. You may opt out of receiving this
        advertising by visiting <a href="https://app.getemails.com/optout">https://app.getemails.com/optout.</a>
      </TermsText>

      <TermsText>
        <b>Do Not Track Signals.</b> Some internet browsers may be configured to send “Do Not Track”
        signals to the online services that you visit. We currently do not respond to do not track signals.
        To find out more about “Do Not Track,” please visit <a href="http://www.allaboutdnt.com">http://www.allaboutdnt.com.</a>
      </TermsText>

      <TextBrand>3. How Does Family Core Use Personal Information?</TextBrand>

      <TermsText>We may use Personal Information:</TermsText>

      <TermsText>
        <ul>
          <li>
            To operate, maintain, administer, and improve our Sites.
          </li>
          <li>
            To respond to your inquiries and fulfill your requests, including to track and
            administer services you have required and to send you newsletters.
          </li>
          <li>
            To send administrative information to you, for example, to confirm orders or
            notice of a license or subscription expiration.
          </li>
          <li>
            To communicate with you, better understand your needs and interests,
            personalize our experience with the Sites, and provide you with related customer
            service.
          </li>
          <li>
            To send you marketing communications, including via email in compliance with
            applicable laws, that we believe may be of interest to you.
          </li>
          <li>
            To personalize your experience on the Sites by presenting products and offers
            tailored to you.
          </li>
          <li>
            To provide support and maintenance for the Sites and our services and to help
            diagnose problems.
          </li>
          <li>
            For our business purposes, such as data analysis, audits, fraud monitoring and
            prevention, administering the Sites, developing and offering new products,
            enhancing, improving or modifying our Sites and services, sending you service
            announcements, technical notices, updates and security alerts, identifying usage
            trends, determining the effectiveness of our promotional campaigns, monitoring
            regions from which users navigate to the Sites, and operating and expanding our
            business activities.
          </li>
          <li>
            As we believe to be necessary or appropriate: (a) under applicable law, including
            laws outside your country of residence; (b) to comply with legal process; (c) to
            respond to requests from public and government authorities including public and
            government authorities outside your country of residence; (d) to enforce our
            terms and conditions; (e) to protect our operations; (f) to protect our rights,
            privacy, safety or property, and/or that of you or others; and (g) to allow us to
            pursue available remedies or limit the damages that we may sustain.
          </li>
        </ul>
      </TermsText>
      <TermsText>
        If you choose to provide Personal Information concerning your religious affiliation or beliefs to or
        through our Sites, you specifically consent to Family Core using such Personal Information for
        any of the purposes set forth in this Privacy Policy and sharing such Personal Information as set
        forth in this Privacy Policy.
      </TermsText>

      <TextBrand>4. How Does Family Core Share Personal Information?</TextBrand>

      <TermsText>
        We only share your Personal Information as described in this Privacy Policy.
      </TermsText>

      <TermsText>
        <b>Individual Posts/ Endorsements:</b>
      </TermsText>

      <TermsText>
        Personal Information may be voluntarily disclosed by you to or through our Sites when you post
        information and materials to our Sites. This information may also appear in other public ways,
        such as through search engines or other publicly available platforms and may be “crawled” or
        searched by third parties. Such posts may be shared by Family Core. Similarly, if you share a
        testimonial or endorsement about your experience on our Sites, Family Core may share that
        information publicly. It is your responsibility to refrain from posting any information that you do
        not want to reveal to the public at large.
      </TermsText>

      <TermsText>
        <b>Business Transfers:</b>
      </TermsText>

      <TermsText>
        In some cases, we may buy or sell assets or businesses. In these types of transactions, user
        information is typically one of the business assets that is transferred. Family Core may disclose
        Personal Information to a third party in the event of any reorganization, merger, sale, joint
        venture, assignment, transfer, or other disposition of all or any portion of our business, assets,
        or stock (including in connection with any bankruptcy or similar proceedings) or in connection
        with negotiations regarding any such transaction.
      </TermsText>

      <TermsText>
        <b>Protection of Family Core and Others:</b>
      </TermsText>

      <TermsText>
        We may release Personal Information when we believe that release is necessary or appropriate
        (a) under applicable law, including laws outside your country of residence; (b) to comply with
        legal process; (c) to respond to requests from public and government authorities including public
        and government authorities outside your country of residence; (d) to enforce or apply our
        conditions of use and other agreements; (e) to protect our rights, privacy, safety, operations or
        property, and/or that of our employees, Affiliates, users, or any other person or entity (f) to
        protect against, investigate, or deter fraudulent, harmful, unauthorized, unethical or illegal
        activity; and (g) to allow us to pursue available remedies or limit the damages that we may
        sustain. This includes exchanging information with other companies and organizations for fraud
        protection and credit risk reduction.
      </TermsText>

      <TermsText>
        <b>Consent:</b>
      </TermsText>

      <TermsText>
        With your prior consent, we may use or share your Personal Information for any other reason,
        such as when you consent to participate in surveys, instruct us to take a specific action with
        respect to your Personal Information, or opt into third party marketing communications.
      </TermsText>

      <TextBrand>5. How Does Family Core Use and Disclose Other Information and Aggregated Information?</TextBrand>

      <TermsText>
        We may use and disclose Other Information and Aggregated Information for any purpose,
        except where we are required to do otherwise under applicable law. If we are required to treat
        Other Information as Personal Information under applicable law, then we may use it for all the
        purposes for which we use and disclose Personal Information.
      </TermsText>

      <TermsText>
        In some instances, we may combine Other Information with Personal Information (such as
        combining your name with your geographical location). If we do, we will treat the combined
        information as Personal Information for as long as it is combined.
      </TermsText>

      <TextBrand>6. How Does Family Core Secure Personal Information About Me?</TextBrand>

      <TermsText>
        We have implemented organizational, technical, and administrative measures to protect
        Personal Information within our organization, including security controls to prevent unauthorized
        access to our systems. While we take reasonable steps to secure your Personal Information
        from loss, misuse, interference, unauthorized access, modification, or disclosure, you should be
        aware no security procedures or protocols are ever guaranteed to be 100 percent secure from
        intrusion or hacking, and there is therefore always some risk assumed by sharing Personal
        Information online. If you have reason to believe that your interaction with us is no longer secure
        (for example, if you feel that the security of your account has been compromised), please
        immediately notify us in accordance with the “Questions, Concerns and Complaints” section
        below.
      </TermsText>

      <TextBrand>7. How Can I Access, Correct, Remove or Obtain a Copy of Personal Information Family Core Has Collected About Me?</TextBrand>

      <TermsText>
        If you feel that any Personal Information about you that has been collected and stored by Family
        Core is incorrect, or if you would like Personal Information about you removed from our
        systems, please notify us at <a href="info@thefamilycore.com">info@thefamilycore.com</a> so that we may consider and respond to
        your request in accordance with applicable law. You may also request access to or a copy of
        your Personal Information held by Family Core, by sending a written request
        to <a href="info@thefamilycore.com">info@thefamilycore.com</a>
      </TermsText>

      <TermsText>
        For your protection, we may only implement requests with respect to the Personal Information
        associated with the particular email address that you use to send us your request, and we may
        need to verify your identity before implementing your request. We will try to comply with your
        request as soon as reasonably practicable, subject to applicable law.
      </TermsText>

      <TermsText>
        Please note that we may need to retain certain information for record keeping purposes and/or
        to complete any transactions that you began prior to requesting such change or deletion.
      </TermsText>

      <TextBrand>8. For How Long Will Family Core Retain My Personal Information?</TextBrand>

      <TermsText>
        We will retain your Personal Information for the period necessary to fulfill the purposes for which
        collected and as outlined in this Privacy Policy unless a longer retention period is required or
        permitted by law.
      </TermsText>

      <TextBrand>9. What Choices Do I Have?</TextBrand>

      <TermsText>
        You can always opt not to use our Sites. You can also opt not to voluntarily disclose information
        to or through our Sites. However, if you elect not to voluntarily provide Family Core with certain
        Personal Information, it may affect your experience of the Sites and we may be limited in
        responding to your inquiries or providing services to you.
      </TermsText>

      <TermsText>
        You may opt out of receiving marketing messages from Family Core by sending an email to
        <a href="info@thefamilycore.com"> info@thefamilycore.com</a> or by unsubscribing through the unsubscribe or opt-out link in our
        emails. We may need to verify your identity before implementing your request. We will try to
        comply with your request as soon as reasonably practicable. Please note that if you opt-out of
        receiving marketing-related emails from us, we may still send you important administrative
        messages.
      </TermsText>

      <TermsText>
        You may also opt-out by using a Universal Opt-Out Mechanism. If Family Core receives an opt-
        out request from a Universal Opt-Out Mechanism, it will comply with the request as if it came
        directly from you.
      </TermsText>

      <TermsText>
        If you have provided specific consent to use your Personal Information, you may withdraw that
        consent by sending an email to  <a href="info@thefamilycore.com">info@thefamilycore.com.</a>
      </TermsText>

      <TermsText>
        Your authorized agent may exercise the rights set forth in this Privacy Policy on your behalf, but
        the agent must first demonstrate it has the lawful authority to act on your behalf.
      </TermsText>

      <TermsText>
        If you or your authorized agent’s request to access, correct or delete data, or to opt out of
        marketing messages, is denied by Family Core, you may appeal the decision by sending an
        email to <a href="info@thefamilycore.com">info@thefamilycore.com.</a> and stating your grounds for appeal.
      </TermsText>

      <TextBrand>10. Third Party Sites</TextBrand>

      <TermsText>
        The Sites may permit you to link to other websites on the Internet through direct links or through
        applications such as “share” or “Like” buttons, and other websites likewise may contain links to
        the Sites. The information practices or content of such other websites is governed by the privacy
        statements of those websites and not by this Privacy Policy. We encourage you to review the
        privacy policies found on such other websites, services, and applications to understand how
        your information may be collected and used.
      </TermsText>

      <TextBrand>11. Sensitive Information</TextBrand>

      <TermsText>
        We understand that you may store sensitive information, (i.e., social security numbers, drivers
        license information, or passport information, etc.) in Family Core sites or app for your
        convenience and to easily access when mobile. Family Core does not assume any liability for
        unauthorized access by a third party. You are assuming the risk and liability for taking
        advantage of this convenience, and further hold harmless and waive any claim against Family
        Core for damages resulting from a third party unauthorized access and wrongful use of such
        sensitive information.
      </TermsText>

      <TextBrand>12. Changes to this Privacy Policy</TextBrand>

      <TermsText>
        We reserve the right to modify this Privacy Policy at any time. We encourage you to periodically
        review this page for the latest information on our privacy practices. If we make material
        changes to this Privacy Policy we will communicate that a change has been made to the policy
        in a reasonable manner which may include posting a notice on our Sites and/or posting the new
        Privacy Policy on our Sites.
      </TermsText>

      <TermsText>
        Any modifications to this Privacy Policy will be effective upon our posting of the new terms and/
        or upon implementation of the new changes on the Sites (or as otherwise indicated at the time
        of posting). In all cases, your continued use of the Sites and/or our services after the posting of
        any modified Privacy Policy indicates your acceptance of the terms of the modified Privacy
        Policy.
      </TermsText>

      <TextBrand>13. Children’s Privacy</TextBrand>

      <TermsText>
        Our Sites and services are not directed to children under the age of 13, we do not intentionally
        gather Personal Information from visitors who are under the age of 13, and such individuals are
        prohibited hereby from providing Personal Information, without their parent’s or guardian’s
        consent. If a parent or guardian becomes aware that his or her child has provided us with
        information without his or her consent, he or she should contact us at the contact information
        provided above. We will delete such information from our files as soon as reasonably
        practicable.
      </TermsText>

      <TextBrand>14. Questions, Concerns or Complaints: Contact Us.</TextBrand>

      <TermsText>
        Your privacy is important to us. If you have any questions, concerns, or complaints regarding the
        way we collect and handle your information, please contact us by email
        at <a href="mailto:info@thefamilycore.com">info@thefamilycore.com</a> or by mail at Family Core, LLC, PO Box 25236, Scottsdale, AZ
        85255, ATTN: Customer Service - Privacy. Because email communications are not secure,
        please do not include credit card information or other sensitive information in your emails to us.
      </TermsText>

      <TermsText>
        Family Core will take any privacy complaint seriously and any complaint will be assessed by an
        appropriate person with the aim of resolving any issue in a timely and efficient manner. We
        request that you cooperate with us during this process and provide us with any relevant
        information that we may need.
      </TermsText>

      <TermsText>
        For Users Outside Of The United States
      </TermsText>

      <TermsText>
        Family Core is headquartered in the United States. Your information, including Personal
        Information that we collect from you, may be transferred to, stored at, and processed by us and/
        or other third parties outside the country in which you reside, including, but not limited to the
        United States, where data protection and privacy regulations may not offer the same level of
        protection as in your jurisdiction. We will take all reasonable steps to ensure that your data is
        treated securely and in accordance with this Privacy Policy.
      </TermsText>

      <TermsText>
        References to “Personal Information” in this Privacy Policy are equivalent to “Personal Data”
        governed by European Union data protection legislation. We use the following safeguards if
        Family Core transfers Personal Information originating from the European Union to other
        countries not deemed adequate under applicable data protection law:
      </TermsText>

      <TermsText><b>Controller</b></TermsText>

      <TermsText>
        Family Core is the controller of your Personal Information for purposes of European data
        protection legislation. See the Contact Us section above for contact details.
      </TermsText>

      <TermsText><b>Legal Bases for Processing Personal Information</b></TermsText>

      <TermsText>
        We only use your Personal Information as permitted by law. We may use or share your
        Personal Information as set forth in this Privacy Policy.
      </TermsText>

      <TermsText>
        We may use your Personal Information for reasons not described in this Privacy Policy where
        permitted by law and where the reason is compatible with the purpose for which we collected it.
        If we need to use your Personal Information for an unrelated purpose, we will notify you and
        explain the applicable legal basis.
      </TermsText>

      <TermsText><b>Your Rights</b></TermsText>

      <TermsText>
        European data protection laws give you certain rights regarding your Personal Information. You
        may ask us to take the following actions in relation to your Personal Information that we hold:
      </TermsText>

      <TermsText>
        <ul>
          <li>
            <b>Opt-out.</b> Stop sending you direct marketing communications. You may continue to
            receive service-related and other non-marketing emails.
          </li>
          <li>
            <b>Access.</b> Provide you with information about our processing of your Personal
            Information and give you access to your Personal Information.
          </li>
          <li>
            <b>Correct.</b> Update or correct inaccuracies in your Personal Information.
          </li>
          <li>
            <b>Delete.</b> Delete your Personal Information.
          </li>
          <li>
            <b>Transfer.</b> Transfer a machine-readable copy of your Personal Information to you or a
            third party of your choice.
          </li>
          <li>
            <b>Restrict.</b> Restrict the processing of your Personal Information.
          </li>
          <li>
            <b>Object.</b> Object to our reliance on our legitimate interests as the basis of our processing
            of your Personal Information that impacts your rights.
          </li>
        </ul>
      </TermsText>

      <TermsText>
        You can submit these requests by email to <a href="mailto:info@thefamilycore.com">info@thefamilycore.com</a> or our postal address
        provided above. We may request specific information from you to help us confirm your identity
        and process your request. Applicable law may require or permit us to decline your request. If
        we decline your request, we will tell you why, subject to legal restrictions. If you would like to
        submit a complaint about our use of your Personal Information or response to your requests
        regarding your Personal Information, you may contact us at or submit a complaint to the data
        protection regulator in your jurisdiction.
      </TermsText>
    </Content>
  </>
);
