import { FormControl, MenuItem, Select }    from '@material-ui/core';
import React, { FC, useState,useEffect }    from 'react';
import { useHistory }                       from 'react-router-dom';
import styled                               from 'styled-components';
import { queryParams }                      from '../../../../shared/functions';
import { UploadContactModal }               from '../../../../shared/components/modals/UploadContact/UploadContactModal';
import { UploadDocumentModal }              from '../../../../shared/components/modals/UploadDocument/UploadDocumentModal';
import { UploadEventModal }                 from '../../../../shared/components/modals/UploadEvent/UploadEventModal';
import { UploadTaskModal }                  from '../../../../shared/components/modals/UploadTask/UploadTaskModal';
import { EAppRoutes }                       from '../../../../shared/constants/constants';
import { documentTypes, documentTypesList } from '../../../../shared/documentTypes';
import { ButtonAdd }                        from './ButtonAdd';
import { LimitedModal }                     from '../../../../shared/components/modals/UploadTask/LimitedModal';
import { useLoadDocumentsPage }             from '../../../../shared/components/modals/UploadDocument/queries';
import { useFetchSchedulePage }             from '../../../Schedule/queries';
import { useFetchFamilies }                 from '../../../../shared/queries/family';

export const IconWrapper = styled.span`
  display: flex;
  margin-right: 10px;
`;

const DropdownWrapper = styled.div`
  flex: 1;
  display: flex;
  margin-right: 12px;
  width: 100%;
  max-width: 280px;
`;

const StyledDropdown = styled(FormControl)`
  flex: 1;
  width: 100%;
  
  .MuiSelect-outlined {
    background-color: #fff;
  }
  
  .MuiOutlinedInput-root {
    margin-top: 0;
  }
`;

const FlexSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 18px 0 12px;
  align-items: flex-end;
`;

interface IAddToFamilyCore {
  familyId?: string;
  familyMemberId?: string;
  isActivePayment?: boolean;
}

export const FlexibleSelect = styled(Select)<{ display?: string }>`&& {
  .MuiSelect-root {
    display: ${ ({ display }) => display || 'block' };
  };
}`;

export const AddToFamilyCore: FC<IAddToFamilyCore> = ({ familyId, familyMemberId, isActivePayment }) => {
  const history = useHistory();
  const params = queryParams();
  const currentDate = new Date();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();

  const [ uploadEventModalOpen, setUploadEventModalOpen ] = useState(false);
  const [ uploadContactModalOpen, setUploadContactModalOpen ] = useState(false);
  const [ uploadTaskModalOpen, setUploadTaskModalOpen ] = useState(false);
  const [ addDocumentModalOpen, setAddDocumentModalOpen ] = useState(false);
  const [ openFreeInfo, setOpenFreeInfo ] = useState(false);
  const [ addDocumentTo, setAddDocumentTo ] = useState(documentTypes.documents.name);
  const [ numberOfFamilyDocuments, setNumberOfFamilyDocuments ] = useState<number>(0);

  const { data: schedule } = useFetchSchedulePage(familyId, params?.id, month, year);
  const { data: documents } = useLoadDocumentsPage(
    familyId,
  );

  const { data: families } = useFetchFamilies();
  const [ eventsNumdersForUser, setEventsNumdersForUser ] = useState(0);

  const comparisonDate = new Date("2025-03-25T00:00:00+00:00");

  const foundFamily = families?.find(fam => fam.id === familyId);
  const foundFamilyCreatedAt = foundFamily?.createdAt ? new Date(foundFamily?.createdAt) : null;

  useEffect(() => {
    if (addDocumentTo === 'MaxEventNumber' || 'Events') {
      setEventsNumdersForUser(15);
    } else if (addDocumentTo === 'DocumentCategory' || 'Documents') {
      if (foundFamilyCreatedAt && foundFamilyCreatedAt > comparisonDate) {
        setEventsNumdersForUser(2);
      } else if (foundFamilyCreatedAt && foundFamilyCreatedAt < comparisonDate) {
        setEventsNumdersForUser(8);
      } else {
        setEventsNumdersForUser(2);
      }
    }
  }, [foundFamilyCreatedAt]);

  useEffect(() => {
    if (documents?.pages) {
      const totalCount = documents.pages.reduce((acc, page) => acc + page.totalCount, 0);
      setNumberOfFamilyDocuments(totalCount);
    }
  }, [documents]);

  const onAdd = () => {
    if (addDocumentTo === documentTypes.documents.name) {
      setAddDocumentModalOpen(true);
    } else if (addDocumentTo === documentTypes.tasks.name) {
      setUploadTaskModalOpen(true);
    } else if (addDocumentTo === documentTypes.contacts.name) {
      setUploadContactModalOpen(true);
    } else if (addDocumentTo === documentTypes.events.name) {
      if ( schedule !== undefined && schedule.totalCount === 15 && !isActivePayment) {
        setOpenFreeInfo(true);
      } else {
        setUploadEventModalOpen(true);
      }
    } else if (addDocumentTo === documentTypes.checkIns.name) {
      history.push(EAppRoutes.Locate);
    }
  };

  return (
    <>
      <FlexSection>
        <DropdownWrapper>
          <StyledDropdown variant="outlined">
            <FlexibleSelect
              displayEmpty
              value={addDocumentTo}
              display="flex"
              onChange={(event: any) => setAddDocumentTo(event.target.value)}
            >
              { documentTypesList.map((item, index) => (
                <MenuItem
                  key={index}
                  value={item.name}
                >
                  <IconWrapper>
                    { item.icon }
                  </IconWrapper>

                  { item.name }
                </MenuItem>
              )) }
            </FlexibleSelect>
          </StyledDropdown>
        </DropdownWrapper>

        <ButtonAdd onClick={() => onAdd()} />
      </FlexSection>

      { addDocumentModalOpen && (
        <UploadDocumentModal
          familyId={familyId}
          familyUserId={familyMemberId}
          open={addDocumentModalOpen}
          onClose={() => setAddDocumentModalOpen(false)}
        />
      ) }

      { uploadTaskModalOpen && (
        <UploadTaskModal
          familyId={familyId}
          open={uploadTaskModalOpen}
          onClose={() => setUploadTaskModalOpen(false)}
        />
      ) }

      { uploadContactModalOpen && (
        <UploadContactModal
          familyId={familyId}
          open={uploadContactModalOpen}
          onClose={() => setUploadContactModalOpen(false)}
        />
      ) }

      { uploadEventModalOpen && (
        <UploadEventModal
          familyId={familyId}
          open={uploadEventModalOpen}
          onClose={() => setUploadEventModalOpen(false)}
        />
      ) }

      <LimitedModal
        openFreeInfo={openFreeInfo}
        entityName={addDocumentTo}
        eventsNumdersForUser={eventsNumdersForUser}
        setOpenFreeInfo={setOpenFreeInfo}
      />
    </>
  );
};
