import { Grid, Hidden, Typography }                                        from '@material-ui/core';
import qs                                                                  from 'qs';
import { FC, useEffect, useMemo, useState, useRef }                         from 'react';
import { useMutation }                                                     from 'react-query';
import { useHistory }                                                      from 'react-router-dom';
import styled                                                              from 'styled-components';
import imageCheckout                                                       from '../../../images/chose-your-plan-select-page.png';
import { LogoWhite }                                                       from '../../shared/components/Logo';
import { TermsModal }                                                      from '../../shared/components/modals/Terms/TermsModal';
import { EAppRoutes, EAuthRoutes }                                         from '../../shared/constants/constants';
import { addDays, queryParams }                                            from '../../shared/functions';
import { IPaymentMethod }                                                  from '../../shared/interfaces/stripe';
import { useFetchFamilies }                                                from '../../shared/queries/family';
import { FormAction }                                                      from '../../shared/styledComponents';
import { isIOS, isNative }                                                 from '../../shared/utils/platform';
import { getAuthToken }                                                    from '../../utils/authHelpers';
import { ISubscriptionState, getSubscriptionState }                        from '../../utils/subscription';
import {
  AuthSVGSceneHolder,
  PageHeader,
  PageHeaderText,
  SignUpContainer,
  WrapperLogo,
  MultiButton,
}                                                                          from '../shared/styles';
import { CheckoutForm }                                                    from './CheckoutForm';
import { createFamilyPaymentMethod }                                       from '../../shared/queries/payments';

const BackButton = styled(MultiButton)`&& {
  margin: 15px 0 0;
  max-width: 170px;
  color: white;
  border: solid 2px white;
  margin-left: -25px;

  ${ ({ theme: { breakpoints } }) => breakpoints.down('md') } {
      font-size: 15px;
      border: solid 1px white;
      margin-left: 0;
    }

    ${ ({ theme: { breakpoints } }) => breakpoints.down('sm') } {
      font-size: 15px;
      border: solid 1px white;
      width: 89px;
      padding: 0;
      margin-right: 19px;
      margin-left: 0;
    }
}`;

const StyledLogo = styled(LogoWhite)`
  height: 50px;
  width: 110px;
  margin-bottom: 40px;
  
  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    height: 60px;
    width: 130px;
    margin-bottom: 90px;
  }
`;

const SelectPlanWrapper = styled.div`
  background-color: #5274be;
`;

const BackButtonWrapper = styled(Grid)`
`;

const AvailablePlan = styled(FormAction)`&& {
  background-color: #f9f9f930;
  height: auto;
}`;

const SelectPlanContainer = styled.div`&& {
  width: 200px;
  margin: auto;
  text-align: center;
  
  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    margin: 0;
  }
  
  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    width: auto;
    margin: 0;
    text-align: left;
  }
}`;

const HeaderSignUpContainer = styled(SignUpContainer)`&& {
  padding: 28px;
  min-height: auto;
  
  ${ ({ theme: { breakpoints } }) => breakpoints.up('sm') } {
    padding: 28px 40px 60px;
  }
  
  ${ ({ theme: { breakpoints } }) => breakpoints.up('md') } {
    padding: 28px 0 40px;
  }
}`;

const PlanSelectWrapper = styled(Grid)`&& {
  width: 100%;
  margin: 22px auto 20px 0;
  
  .MuiInputLabel-root {
    color: #fff;
  }
  
  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
    width: 210px;
    margin: 0 auto 25px 0;
  }
}`;

const AttentionText = styled.div`
  color: #fac05e;
  font-size: 15px;
  text-align: left;
  line-height: 1.56;
  letter-spacing: 0.19px;
  letter-spacing: normal;
  margin: 35px 0 10px 0;
    
  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
    font-size: 18px;
    line-height: 1.6;
    margin: 15px 0 16px 0;
  }
`;

const AttentionTextDescription = styled.div`
  font-size: 12px;
  text-align: left;
  line-height: 1.45;
  letter-spacing: 0.13px;
  font-family: Lato;
  
  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
    font-size: 14px;
  }
  
  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('md') } {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.17px;
  }
`;

const CheckoutHeader = styled(Typography)`&& {
  text-align: center;
  margin: 0 auto ${ isNative ? '0' : '25px' };
  
  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('md') } {
    font-size: 36px;
    margin-bottom: ${ isNative ? '0' : '48px' };
  }
}`;

const FormSignUpContainer = styled(SignUpContainer)`&& {  
  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('sm') } {
    padding: 35px 80px 60px;
  }
  
  ${ ({ theme: { breakpoints } }: any) => breakpoints.up('md') } {
    padding: ${ isIOS ? '40px' : '80px' } 130px 80px;
  }
}`;

const TextUnderline = styled.span`
  text-decoration: underline;
`;

const PrivacyAndTermsLink = styled.span`
  cursor: pointer;
`;

const AppleFinePrint = styled.div`
  font-size: 12px;
  color: #727272;
  line-height: 1.5;
  margin-top: 15px;
  text-align: center;
`;

export const fallbackPrice = '$4.99';

export const Checkout: FC = () => {
  const [ subscription, setSubscription ] = useState<ISubscriptionState | null>(null);
  const [ termsModalOpened, setTermsModalOpened ] = useState(false);
  const { data: families, isLoading: loadingFamilies } = useFetchFamilies({ enabled: !!getAuthToken()?.accessToken });
  const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod | undefined>();

  const params = queryParams();
  const history = useHistory();

  const prevPaymentMethodRef = useRef<IPaymentMethod | undefined>();

  const mutation = useMutation(createFamilyPaymentMethod);

  useEffect(() => {
    setSubscription(getSubscriptionState());
  }, []);

  useEffect(() => {
    if (params?.purchased && !loadingFamilies) {
      onSuccess();
    }
  }, [ params?.purchased, loadingFamilies ]);

  useEffect(() => {
    if (paymentMethod && paymentMethod !== prevPaymentMethodRef.current && isIOS) {
      const delay = setTimeout(() => {
        onSuccess(paymentMethod);
        prevPaymentMethodRef.current = paymentMethod;
      }, 1000);

      return () => clearTimeout(delay);
    }
  }, [paymentMethod]);

  const onSuccess = async (paymentMethod?: IPaymentMethod) => {
    try {
      const data = await mutation.mutateAsync({
        cardToken           : paymentMethod?.id,
        appStoreReceipt     : subscription?.receipt?.nativeData?.appStoreReceipt || undefined,
        googlePurchaseToken : subscription?.receipt?.nativeData?.googlePurchaseToken || undefined,
      });
    } catch (error) {
      console.error(error);
    }

    if (families?.length) {
      history.push(EAppRoutes.Dashboard);
    } else {
      history.push({
        pathname : EAuthRoutes.CreateFamily,
        search   : qs.stringify({
          cardToken  : paymentMethod?.id,
          familyName : params.familyName,
        }),
      });
    }
  };

  const billing = useMemo(() => {
    const product = subscription?.product;
    const productOffer = product?.offers?.find(offer => offer);
    const pricingForMonth = productOffer?.pricingPhases?.find(phase => phase.priceMicros);

    const result = {
      freeTrialPeriod : isNative ? '4 Weeks' : '1 Week',
      price           : isNative ? '4,99' : fallbackPrice,
      title           : isNative ? product?.title : 'Monthly Plan',
    };

    return result;
  }, [subscription]);

  return (
    <>
      <SelectPlanWrapper>
        <HeaderSignUpContainer maxWidth="md">
          <PageHeader
            color="#fff"
            mb="0"
            mbMd="0"
          >
            <WrapperLogo container>
              <BackButtonWrapper
                item
                sm={12}
                md={12}
              >
                <StyledLogo />
              </BackButtonWrapper>
            </WrapperLogo>

            <Grid container>
              <Grid
                item
                sm={6}
                md={5}
              >
                <SelectPlanContainer>
                  <PageHeaderText
                    centeredXs
                    color="#fff"
                  >
                    { isNative ? (billing.title || 'Monthly Plan') : 'Payment Plan' }
                  </PageHeaderText>

                  <PlanSelectWrapper container>
                    <Grid
                      item
                      xs={12}
                    >
                      <AvailablePlan>
                        { billing.price } { isNative ? ` / Month` : billing.title }
                      </AvailablePlan>
                    </Grid>
                  </PlanSelectWrapper>
                </SelectPlanContainer>

                <AttentionText>
                  Free until { addDays(new Date(), 28).toDateString().slice(4) }, then { billing.price } a month. Renews
                  automatically.
                </AttentionText>

                <AttentionTextDescription>
                  { isNative ? (
                    <>
                      <p>By purchasing this subscription you will be able to invite up to 9 members to your family with
                         full access to all application features.</p>
                      <br />

                      This subscription automatically renews for { billing.price } a month after
                      the {billing.freeTrialPeriod} free trial.
                      You can cancel anytime. By signing up for a free trial, you agree to Family Core's&nbsp;

                      <PrivacyAndTermsLink onClick={() => setTermsModalOpened(true)}>
                        <TextUnderline>Terms of Service</TextUnderline> and <TextUnderline>Privacy
                                                                                             Policy</TextUnderline>.
                      </PrivacyAndTermsLink>
                    </>
                  ) : (
                    <>
                      Renews automatically until cancelled. A reminder email will be sent two days prior to first
                      charge, and three days prior to any renewal. You may cancel your subscription at any time via this
                      website.
                    </>
                  ) }
                </AttentionTextDescription>

                <TermsModal
                  open={termsModalOpened}
                  onClose={() => setTermsModalOpened(false)}
                />
              </Grid>

              <Hidden xsDown>
                <Grid
                  item
                  sm={6}
                  md={7}
                >
                  <AuthSVGSceneHolder>
                    <img
                      src={imageCheckout}
                      alt="Checkout"
                    />
                  </AuthSVGSceneHolder>
                </Grid>
              </Hidden>
            </Grid>
          </PageHeader>
        </HeaderSignUpContainer>
      </SelectPlanWrapper>

      <FormSignUpContainer maxWidth="md">
        <CheckoutHeader variant="h4">
          Checkout
        </CheckoutHeader>

        <CheckoutForm onSuccess={(method) => {
          setPaymentMethod(method);
          onSuccess(method);
        }} />

        { isIOS && (
          <AppleFinePrint>
            Payment will be charged to your Apple ID account at the confirmation of purchase.
            Subscription automatically renews unless it is canceled at least 24 hours before the end of the current
            period.
            Your account will be charged for renewal within 24 hours prior to the end of the current period.
            You can manage and cancel your subscriptions by going to your account settings on the App Store after
            purchase.
          </AppleFinePrint>
        ) }
      </FormSignUpContainer>
    </>
  );
};

export default Checkout;
