import styled                from 'styled-components';
import DialogContent         from '@material-ui/core/DialogContent';
import DialogContentText     from '@material-ui/core/DialogContentText';
import { TextBrand }         from '../styledComponents';
import { UploadDialogTitle } from './modals/styles';

const TermsText = styled(DialogContentText)`
  font-family: 'Lato';
  font-size: 16px;
  color: #272932;
  line-height: 26px;

  ul {
    list-style: disc;
    margin-left: 30px;
  }
`;

const Content = styled(DialogContent)`
  word-break: break-word;
  
  ${ TextBrand } {
    margin-bottom: 5px;
  }
`;

export const TermsAndService = () => (
  <>
    <UploadDialogTitle>
      <p>FAMILY CORE APP TERMS OF SERVICE</p>
      <TermsText>Last Updated: 03/27/2025</TermsText>
    </UploadDialogTitle>

    <Content>
      <TextBrand>1. INTRODUCTION.</TextBrand>

      <TermsText>
        The Family Core App (the “App”) is a web- and app-based platform that facilitates the
        cooperation of parents, co-parents, foster parents, and guardians of children in blended families
        and other relationships having to manage schedules, visitation, obligations, and shared duties
        amongst them.
      </TermsText>

      <TermsText>
        Upon using The App, User enters into a legally binding agreement (“Agreement”) with The
        Family Core, LLC (“Family Core”) and agrees to all of the terms of service set forth below
        (“Terms of Service”) as well as the Family Core Privacy Policy found here.
      </TermsText>

      <TermsText>
        Please read this agreement carefully in its entirety before uploading, downloading, or using any
        content or features available on The App. If User has any questions regarding this Agreement
        or its terms, please contact Family Core by email at: info@thefamilycore.com.
      </TermsText>

      <TextBrand>2. USER REPRESENTATIONS.</TextBrand>

      <TermsText>
        Users represents that User is (1) 18 years of age or older, or (2) 13 years of age or older and
        has obtained parental or guardian consent to enter this Agreement and use The App and its
        services.
      </TermsText>

      <TermsText>
        User will be uploading data including, but not limited to, events, personal contacts, current geo-
        locations, documents (such as medical, legal, and financial, along with other information and
        personal relevant data. User will not provide information to or through The App related to a third
        party without that third party’s knowledge and consent. By providing information or uploading
        content to The App, User represents and warrants that User is entitled to and has the requisite
        rights and permissions to submit such information or content, and that the information or content
        is accurate, not confidential, and not in violation of any contractual restrictions or third-party
        rights.
      </TermsText>

      <TermsText>
        Users can create a User account to access the tools, resources, calendar, and other content
        through The App. User agrees to: (1) keep User’s password secure and confidential; (2) not
        permit others to use User’s login credentials to access User’s account; (3) refrain from using
        other Users’ accounts; and (4) refrain from charging anyone for access to any portion of The
        App or its services. User is responsible for anything that happens to or through User’s account
        until User’s account is terminated (as set forth below) or User can establish that User’s account
        security was compromised through no fault of User.
      </TermsText>

      <TermsText>
        User agrees that The App can use the processor, bandwidth, and storage hardware on User’s
        computer, mobile device, tablet, wearable device, speaker, or any other device User utilizes to
        access The App in order to (1) facilitate the operation of The App, (2) provide advertising and
        other information to User, and (3) allow Family Core and its partners, subsidiaries, and affiliates
        (collectively, “Family Core”) to do the same.
      </TermsText>

      <TermsText>
        User understands and agrees that the content available on The App may not always be
        accurate and that User should review all content for accuracy and appropriateness before using
        it and provide any corrections for update.
      </TermsText>

      <TextBrand>3. DIRECT PURCHASES AND MEMBERSHIP.</TextBrand>

      <TermsText>
        Some of the features and content available on The App is free. A free version of The App exists
        that allows for certain features and content to be used. Additional features and content are
        accessible and downloadable to Users who enroll and make a direct purchase of the additional
        features and selected content and pay for The App subscription. User’s subscription is made at
        the prices effective at the time of subscription. To make a direct purchase or acquire a
        subscription, User must provide The App with a valid, accepted method of payment (“Payment
        Method”). To retain a subscription, User must update User’s Payment Method, as necessary, to
        ensure that a current, valid, accepted Payment Method is always on file with The App. Paid
        Membership will continue and renew automatically on a monthly billing cycle until it is cancelled
        by User or terminated by Family Core. The App will email User seven (7) days before User’s
        Membership renews. User must cancel User’s subscription before it renews in order to avoid
        paying Membership fees for the next billing cycle. If User does not timely cancel User’s
        Membership, The App will process the same Payment Method on file to renew User’s
        Membership. For more information about Membership cancellation, see Section 13,
        “Cancellation and Refunds,” below.) If User fails to maintain a valid, accepted Payment Method
        on file such that payment cannot be made when it is time for the Membership to renew, User’s
        Membership will automatically be cancelled, and User will no longer have Membership access
        to The App.
      </TermsText>

      <TextBrand>4. INFORMATION UPLOADED.</TextBrand>

      <TermsText>
        User will be uploading data including, but not limited to, events, personal contacts, current geo-
        locations, documents (such as medical, legal, and financial, along with other information and
        personal relevant data to The App. By uploading content to The App, Contributor avows that (1)
        Contributor owns and/or has the right to share such content, (2) such content does not violate
        these Terms of Service, (3) such content does not violate applicable law or regulation, (4) such
        content does not violate the intellectual property rights or other rights of any third party, (5) such
        content does not contain profanity, nudity, sexually explicit content, slander, illegal acts, or any
        other obscenity or vulgarity, and (6) such content does not contain harassing, bullying,
        threatening, defamatory, abusive, hateful, or illegal content.
      </TermsText>

      <TermsText>
        All content will be subject to review by Family Core once submitted and pursuant to a complaint
        or notification, and at any other time. Family Core reserves the right to remove any content at
        any time for any or no reason and without prior notification to User. The posting of a User’s
        content to The App does not imply affiliation with or endorsement of User or his or her content
        by Family Core.
      </TermsText>

      <TermsText>
        Notwithstanding the foregoing admonitions and agreements, Family Core shall not be liable for
        any of the content uploaded by Users to The App, nor shall it be liable for removing (or not
        removing) any content.
      </TermsText>

      <TermsText>
        <b>Google Calendar and OAuth.</b> The following describes how The Family Core shares
        information when you use our services that integrates with GoogleOAuth. When you
        use GoogleOAuth to access our services we collect your name, email address, profile
        picture and any other information you choose to make available through your google
        account. We use the information collected through Google OAuth to authenticate your
        identity, provide and improve our services and communicate with you about our
        services.
      </TermsText>

      <TextBrand>5. PROPRIETARY RIGHTS AND COPYRIGHTS.</TextBrand>

      <TermsText>
        User acknowledges that The App contains content that is protected by copyright, trademark,
        patent, or other proprietary rights, and that these rights are valid and protected in all forms,
        content, and technologies existing now or hereinafter developed.
      </TermsText>

      <TermsText>
        User will immediately notify The App if User becomes aware or suspects that any third party (1)
        has gained unauthorized access to the content through User, (2) is wrongfully using the content,
        in whole or in part, or (3) is violating any users’ or of Family Core’s intellectual property rights.
      </TermsText>

      <TextBrand>6. DISCLAIMERS AND LIMITATION OF LIABILITY.</TextBrand>

      <TermsText>
        Family Core makes no warranties about The App of any kind, whether express, implied, or
        statutory. To the extent allowable by law, Family Core disclaims any express, implied, and
        statutory warranties regarding the content, including warranties of satisfactory quality,
        merchantability, fitness for a particular purpose, or non-infringement. Family Core does not
        warrant that The App will offer uninterrupted service or will be error-free. Family Core does not
        warrant the accuracy of the captioning, keywording or any other information associated with the
        content. For as long as Family Core continues to offer The App and its services, Family Core
        may seek to update, improve, and expand the services provided by The App. As a result,
        Family Core allows Users to access The App as it may exist and be available on any given day
        and has no other obligations, except as expressly stated in these Terms of Service. Family Core
        may modify, replace, refuse access to, suspend, or discontinue The App or its services, partially
        or entirely, or change and modify prices for all or part of the services provided to Users,
        including Membership fees, in its sole discretion. All of these changes shall be effective upon
        The App’s posting of the same on its website or by direct communication to Users, unless
        otherwise noted. Family Core reserves the right to withhold or remove any content from The
        App at any time, with or without notice, if deemed by Family Core to be contrary to these Terms
        of Service. For avoidance of doubt, Family Core has no obligation to store, maintain or provide
        a copy of any content that Contributors upload to The App, except as set forth in the Family
        Core Privacy Policy.
      </TermsText>

      <TermsText>
        The App may include advertisements for, make offers regarding, and/or provide hyperlinks to
        third-party websites, products, and services. Family Core is not responsible for the websites,
        products, and services provided by such third parties.
      </TermsText>

      <TermsText>
        Under no circumstances shall Family Core, its officers, directors, employees, contractors, or
        agents be liable for any direct, indirect, incidental, special, or consequential damages that result
        from (1) any User’s use of, or inability to use, The App, (2) the violation of any right to privacy by
        User, (3) the violation of any copyright law or any other intellectual property right by a User or a
        Contributor, or (4) any mistakes, omissions, interruptions, deletions, errors, defects, viruses,
        delays in operation or transmission, theft, destruction, unauthorized access, or any other failure
        of The App to perform under this Agreement.
      </TermsText>

      <TermsText>
        Notwithstanding the foregoing, User agrees that Family Core’s maximum liability arising out of
        or in connection with User’s use of, or inability to use, The App shall, to the extent permitted by
        law, be limited to five (5) times the value paid by User for the relevant features, services, and
        content.
      </TermsText>

      <TextBrand>7. INDEMNIFICATION</TextBrand>

      <TermsText>
        User agrees to indemnify, defend, save and hold harmless Family Core, its officers, directors,
        employees, contractors, and agents, to the fullest extent allowed by law from and against any
        and all liabilities, demands, suits, claims, actions, arbitrations, administrative proceedings,
        awards, judgments, losses, fines, penalties, or damages (including, without limitation, attorneys’
        fees, investigative fees, expert fees, and costs) arising out of or in connection with (1) User’s
        use of The App, (2) any unauthorized use or allegedly unauthorized use of any content, and (3)
        any other breach by User of User’s obligations under this Agreement. The terms of this section
        shall survive the expiration or earlier termination of this Agreement.
      </TermsText>

      <TextBrand>8. TERMINATION</TextBrand>

      <TermsText>
        Family Core, in its sole discretion, may terminate any User’s account, access to, and/or use of
        The App and its products and services at any time and for any reason, at which time: (1) User’s
        License will terminate immediately upon notice of same to User by Family Core, (2) all rights
        granted to User by the License will immediately revert to Family Core, and (3) any further
        exploitation of any content by User shall constitute an infringement of copyright.
      </TermsText>
      <TermsText>
        User may terminate his or her User account as set forth in Section 11 below.
      </TermsText>
      <TermsText>
        Section 5 (“Proprietary Rights and Copyrights”), Section 6 (“Disclaimers and Limitation of
        Liability”), and Section 10 (“Miscellaneous Terms”) shall survive termination of this Agreement.
      </TermsText>

      <TextBrand>9. CHANGES TO TERMS OF SERVICE</TextBrand>

      <TermsText>
        Family Core may amend the Terms of Service at any time and may notify Users by posting an
        updated version of the Terms of Service on The App. Continued use of The App after an
        amended Terms of Service has been posted shall constitute User’s consent to such changes.
      </TermsText>

      <TextBrand>10. MISCELLANEOUS TERMS</TextBrand>

      <TermsText>
        These Terms of Service constitute the entire agreement between User and Family Core
        concerning User’s use of The App and its services. In the event of any inconsistency between
        the Terms of Service and the terms contained in any purchase order or other communication
        sent to User by Family Core, the terms of this Agreement shall prevail.
      </TermsText>

      <TermsText>
        Should any clause of this Agreement be found unenforceable, that will not affect any other
        clause, and each will remain in full force and effect.
      </TermsText>

      <TermsText>
        Any failure by Family Core to enforce these Terms of Service or any provision thereof shall not
        constitute a waiver of Family Core’s right to do so.
      </TermsText>

      <TermsText>
        In the event The App retains an attorney or collection agency to collect any outstanding
        payment due by User, User agrees to pay all collection costs, attorneys' fees, and court costs
        relating thereto, in addition to any outstanding amounts due and applicable interest.
      </TermsText>

      <TermsText>
        The validity of this Agreement and the interpretation and performance of all of its terms shall be
        governed by the laws of the State of Arizona. User hereby irrevocably agrees to submit to the
        personal jurisdiction and venue of any state or federal court located in the County of Maricopa,
        State of Arizona, and expressly waives any claim or defense that such forum is not convenient
        or proper for purposes of any action arising under this Agreement. The parties hereto waive any
        right they may have to a jury trial.
      </TermsText>

      <TermsText>
        User recognizes that the content available on The App possess a special, unique, and
        extraordinary character which makes it difficult to assess the amount of monetary damages The
        App and its Contributors might sustain by any unauthorized use of the content. User agrees
        that irreparable injury would be caused to The App or its Contributors by such unauthorized use,
        and that injunctive relief would be appropriate in the event of a breach of this Agreement.
      </TermsText>

      <TermsText>
        If after notice to User, User fails to take any action which User is obliged to take under the terms
        of this Agreement, Family Core and/or Contributors shall have the right and option, but not the
        duty, to bring an action for specific performance to compel such action by User, and shall be
        entitled to recover all reasonable costs, expenses, attorney's fees and disbursements with
        respect thereto if such specific performance is awarded by a court of competent jurisdiction.
      </TermsText>

      <TermsText>
        This Agreement shall be binding upon and inure to the benefit of the parties hereto and their
        respective legal representatives, successors, and assigns.
      </TermsText>

      <TextBrand>11. CANCELLATION & REFUNDS</TextBrand>

      <TermsText>
        User may cancel User’s Paid Membership as follows: 1) if a User started their account within
        one of the App stores (Apple or Google) they must cancel their account through the originating
        store; 2) if User started t heir account from the Family Core web page, they may cancel their
        account by logging in, going to Manage Family  Manage Account choosing "Cancel." User
        may be asked a series of questions which The App uses to improve its service. When User
        cancels User’s Membership, User cancels only future charges associated with User’s
        subscription. Cancellations are effective at the time of cancellation. The App does not offer
        prorated refunds for cancelled Memberships. User will not receive a refund for the current billing
        cycle.
      </TermsText>

      <TermsText>
        Once the account is cancelled, the platform is set up to “Hard Delete” all data within the account
        90 days after the date of cancellation, regardless of billing cycle and end dates. After 90 days of
        cancellation, all data is NOT retrievable by any method. Therefore, it is the User’s responsibility
        to retrieve, download, copy, all information on the User’s account before 90 days have occurred.
        Therefore, Family Core is held harmless for loss of information.
      </TermsText>

      <TermsText>
        Please keep in mind that User is solely responsible for properly cancelling User’s account.
        While cancellation through User’s account is the simpler and preferred method for cancellation,
        User can also contact support (contact info below) if User needs assistance with cancellation.
      </TermsText>

      <TermsText>
        The App offers a four-week free trial for a User Paid Membership, then the paid billing cycle
        commences. User can choose to cancel prior to the end of the trial and the commencement of
        the billing and beginning of the paid Membership
      </TermsText>

      <TextBrand>12. ALLEGATIONS OF COPYRIGHT INFRINGEMENT</TextBrand>

      <TermsText>
        Family Core respects the intellectual property of others and takes the protection of copyrights
        and all other intellectual property very seriously, and we ask Users to do the same.
      </TermsText>

      <TermsText>
        The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides recourse for copyright
        owners who believe that material appearing on the Internet infringes their rights under US
        copyright law. If User believes in good faith that Media available on The App infringes User’s
        copyright, User (or User’s agent) may send The App a notice requesting that the Media be
        removed, or access to it blocked. The notice must include the following information: (a) a
        physical or electronic signature of a person authorized to act on behalf of the owner of an
        exclusive right that has allegedly been infringed; (b) identification of the copyrighted work
        claimed to have been infringed (or if multiple copyrighted works are covered by a single
        notification, a representative list of such works); (c) identification of the material that is claimed
        to be infringing or the subject of infringing activity, and information reasonably sufficient to allow
        The App to locate the Media within the website; (d) the name, address, telephone number and
        email address (if available ) of the complaining party; (e) a statement that the complaining party
        has a good faith belief that use of the Media in the manner complained of is not authorized by
        the copyright owner, its agent or the law; and (f) a statement that the information in the
        notification is accurate and, under penalty of perjury, that the complaining party is the owner of
        an exclusive right, or is authorized to act on behalf of the owner of an exclusive right, that is
        allegedly infringed. If User believes in good faith that a notice of copyright infringement has
        been wrongly filed against User, the DMCA permits User to send us a counter-notice. Notices
        and counter-notices must meet the then-current statutory requirement imposed by the DMCA.
        See [web link to DMCA copyright rules] for additional details.
      </TermsText>

      <TermsText>
        Notices and counter-notices should be sent to:
      </TermsText>

      <TermsText>
        Family Core, Inc
        <br />
        PO Box 25236
        <br />
        Phoenix, AZ 85255
        <br />
        copyright@FamilyCore.com
      </TermsText>

      <TextBrand>13. CREDIT CARD FRAUD POLICY</TextBrand>

      <TermsText>
        In the unlikely event that User experiences unauthorized use of User’s credit card on The App,
        User is advised to promptly report the fraudulent activity to User’s credit card company. User
        agrees that The App may contact User’s credit card company to report a claim of fraudulent use
        when The App suspects credit card fraud has occurred, or when User provides a specific written
        request for such a report.
      </TermsText>

      <TermsText>
        User requests to report fraud should be sent to Info@thefamilycore.com and must include the
        full name, email address, mailing address and phone number of the person claiming fraud.
        Please call The App at (480) 276-9635, during the day Monday through Friday, to confirm
        receipt of User’s written request. The App will fully cooperate with all government organizations
        to the fullest extent possible to ensure those responsible for credit card fraud are held
        responsible.
      </TermsText>
    </Content>
  </>
);
